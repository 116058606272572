import React from 'react'; // eslint-disable-line no-unused-vars
import { fromJS, Map } from 'immutable';
import { connect } from 'react-redux';
import i18next from 'i18next';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'underscore';
import { Formik, Form, Field } from 'formik';
import ReactTooltip from 'react-tooltip';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  can,
  administerOrganisations,
  muteAdminReportDistribution,
} from '^/capabilities';
import { ACTIVITY_TYPE } from '^/models/activity';
import { clearCollection } from '^/actions/collections';
import { openEmailEditModal, closeTopModal } from '^/actions/modals';
import Alert from '^/components/Alert';
import FormError from '^/components/FormError';
import LiveButton from '^/components/LiveButton';
import PureComponent from '^/components/PureComponent';
import Checkbox from '^/components/Checkbox';
import RadioButton from '^/components/RadioButton';
import QuantityDropdown from '^/components/dropdown/QuantityDropdown';
import { ProductVersionsReportGenerationSubform } from '^/components/activities/ProductVersionsReportGenerationForm';
import ReportDistribution from '^/components/activities/tabs/ReportDistribution';
import ReportGenerationToggle, {
  REPORT_GENERATION,
} from '^/components/activities/tabs/ReportGenerationToggle';
import ReportCost from '^/components/activities/tabs/ReportCost';
import { clearSelection } from '^/actions/actions';
import DateRangePicker from '^/components/date/DateRangePicker';
import ActivityProductVersions from '^/components/activities/ActivityProductVersions';
import CreateActivityAddUsers from '^/components/activities/CreateActivityAddUsers';
import {
  INVITATION_TYPES,
  isReadyToSubmit,
} from '^/components/activities/CreateActivityForm/helpers';
import { isFieldPending, getFormError } from '^/responseStates';

export function buildFakeActivityProductVersion(productOrganisation) {
  const { id, product, product_variant } = productOrganisation.toObject();
  return fromJS({
    id,
    product_version: product.get('latest_version').merge({
      product,
    }),
    product_variant,
    reportgenerationbatch_set: [],
    pulse_behaviour_hierarchy: null,
  });
}

function removeWithPulseIfNoPulsing(props) {
  const { values, setFieldValue } = props;
  if (
    !values.productOrganisations.some(productOrganisation =>
      productOrganisation.get('pulse_behaviour_hierarchy')
    ) ||
    values.productOrganisations.size > 1
  ) {
    if (values.with_pulse === true) {
      props.clearSelection('productOrganisationsAddPulse');
      setFieldValue('with_pulse', false);
    }
  }
}

function addPulsing(props) {
  const { values, setFieldValue, selectedProductOrganisationsPulses } = props;
  if (
    values.productOrganisations.some(productOrganisation =>
      productOrganisation.get('pulse_behaviour_hierarchy')
    ) &&
    values.productOrganisations.size === 1 &&
    selectedProductOrganisationsPulses &&
    selectedProductOrganisationsPulses.size > 0 &&
    values.with_pulse === false
  ) {
    setFieldValue('with_pulse', true);
  }
  if (
    values.productOrganisations.some(productOrganisation =>
      productOrganisation.get('pulse_behaviour_hierarchy')
    ) &&
    values.productOrganisations.size === 1 &&
    ((selectedProductOrganisationsPulses &&
      selectedProductOrganisationsPulses.size === 0) ||
      !selectedProductOrganisationsPulses) &&
    values.with_pulse === true
  ) {
    setFieldValue('with_pulse', false);
  }
}

function getSelectedReportTemplates(productOrganisation, values) {
  const productReportTemplates = productOrganisation.getIn([
    'product',
    'report_templates',
  ]);
  const variantReportTemplates = productOrganisation.getIn([
    'product_variant',
    'report_templates',
  ]);

  const reportTemplates = variantReportTemplates
    ? productReportTemplates.filter(reportTemplate =>
        variantReportTemplates.includes(reportTemplate.get('id'))
      )
    : productReportTemplates;

  return reportTemplates.filter(
    each =>
      values.activity_product_versions[productOrganisation.get('id')]
        .report_templates[each.get('id')]
  );
}

export class CreateActivityFormDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      notifications: this.props.values.notifications || Map({}),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.notifications !== prevState.notifications) {
      this.props.setFieldValue('notifications', this.state.notifications);
    }
    if (this.props.type === ACTIVITY_TYPE.PROFILER) {
      if (
        this.props.values.invitation_type ===
          INVITATION_TYPES.CHOICES.VIA_LINK &&
        this.props.values.invitation_link_usage_limit === null
      ) {
        this.props.setFieldValue('invitation_link_usage_limit', 0);
      } else if (
        this.props.values.invitation_type ===
          INVITATION_TYPES.CHOICES.VIA_EMAIL &&
        this.props.values.invitation_link_usage_limit !== null
      ) {
        this.props.setFieldValue('invitation_link_usage_limit', null);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    addPulsing(props);
    removeWithPulseIfNoPulsing(props);
  }

  getInvitationTypes() {
    const { canAdminOrgs, hasSelectedGroup, type } = this.props;
    if (type === ACTIVITY_TYPE.PROFILER) {
      return [INVITATION_TYPES.CHOICES.VIA_EMAIL];
    }
    if (hasSelectedGroup) {
      return [INVITATION_TYPES.CHOICES.VIA_EMAIL];
    }
    return canAdminOrgs
      ? INVITATION_TYPES.ORDER
      : INVITATION_TYPES.ORDER.filter(
          each => each !== INVITATION_TYPES.CHOICES.VIA_ANON_LINK
        );
  }

  updateFields(data, setFieldValue) {
    Object.keys(data).forEach(key => setFieldValue(key, data[key]));
  }

  editNotification(reportEmailDefaults, notifications, default_template, type) {
    const notification = notifications.has(type)
      ? notifications.get(type).toJS()
      : reportEmailDefaults[default_template];
    const onSubmit = data => {
      const emailData = fromJS(_.pick(data, 'subject', 'content', 'link_text'));
      this.setState(prevState => ({
        notifications: prevState.notifications
          .setIn([type, 'translations', data.lang_code], emailData)
          .setIn([type, 'preferred_lang_code'], data.preferred_lang_code),
      }));
      this.props.closeTopModal();
    };
    this.props.openEmailEditModal(notification, undefined, type, onSubmit);
  }

  render() {
    const {
      response,
      canUserMuteAdminReportDistribution,
      user,
      selectedOrganisation,
      type,
      isModalOpen,
      values,
      setFieldValue,
      reportEmailDefaults,
    } = this.props;

    const {
      start_datetime,
      end_datetime,
      report_visibility_rule,
      report_visibility_date,
      send_zipped_reports_to_activity_creator,
      additional_report_recipients_send_date,
      additional_report_recipients,
    } = values;

    const hasSelectedProduct = !values.productOrganisations.isEmpty();
    const is360 = type === ACTIVITY_TYPE.THREE_SIXTY;
    const isProfile = type === ACTIVITY_TYPE.PROFILER;
    const invitationIsViaEmail =
      values.invitation_type === INVITATION_TYPES.CHOICES.VIA_EMAIL;
    const invitationIsViaLink =
      values.invitation_type === INVITATION_TYPES.CHOICES.VIA_LINK;
    const productVariant = values.productOrganisations.getIn([
      0,
      'product_variant',
    ]);
    const maxNumUsers = isProfile
      ? 1
      : values.productOrganisations.getIn([0, 'number_available'], null);
    const productName =
      values.productOrganisations.getIn([0, 'product_variant', 'name']) ||
      values.productOrganisations.getIn([0, 'product', 'name']);
    const hasInvitationLinkUsageLimit =
      values.invitation_link_usage_limit !== null;
    const invitationTypeShowsCost =
      invitationIsViaEmail ||
      (invitationIsViaLink && hasInvitationLinkUsageLimit);

    const setProductOrganisations = newProductOrganisations => {
      setFieldValue('productOrganisations', newProductOrganisations);
      if (newProductOrganisations.getIn([0, 'product_variant'])) {
        setFieldValue('reportGeneration', REPORT_GENERATION.CHOICES.AUTO);
      }
      setFieldValue(
        'invitation_link_usage_limit',
        newProductOrganisations.getIn([0, 'number_available']) === null
          ? null
          : 0
      );
    };

    const canPulse = values.productOrganisations.some(productOrganisation =>
      productOrganisation.get('pulse_behaviour_hierarchy')
    );

    const showProfileName = values.productOrganisations.some(
      productOrganisation =>
        productOrganisation.get('product') &&
        productOrganisation.get('product').get('activity_type') ===
          ACTIVITY_TYPE.PROFILER
    );
    return (
      <div className="create-activity-form">
        <div className="form-inline">
          <div>
            <label htmlFor="name">{i18next.t('Activity name')}</label>
            <div className="form-item">
              <Field
                type="text"
                placeholder={i18next.t(
                  'Product - Detail - Team/Respondent - Date'
                )}
                name="name"
                id="name"
                autoComplete={isModalOpen && 'off'}
              />
              <FormError response={response} formKey="name" />
            </div>
            {isProfile && showProfileName && (
              <div className="tooltip">
                <span data-tip data-for="activity-name-tooltip">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="activity-name-tooltip"
                  textColor="black"
                  backgroundColor="white"
                >
                  {i18next.t(
                    'This is the name that will appear on your activity list page and dashboard.'
                  )}
                </ReactTooltip>
              </div>
            )}
          </div>

          <div>
            <label>{i18next.t('Dates')}</label>
            <div className="form-item">
              <DateRangePicker
                onSave={(field, newValue) => setFieldValue(field, newValue)}
                minDate={moment()}
                className="date-range-picker-flex"
                showTime
                first={{
                  fieldName: 'start_datetime',
                  value: start_datetime,
                  isLoading: isFieldPending(response, 'start_datetime'),
                  defaultValue: i18next.t('Start date'),
                  prefix: `${i18next.t('Start')}:`,
                  error: getFormError(response, 'start_datetime'),
                }}
                second={{
                  fieldName: 'end_datetime',
                  value: end_datetime,
                  isLoading: isFieldPending(response, 'end_datetime'),
                  defaultValue: i18next.t('Close date'),
                  prefix: `${i18next.t('Close')}:`,
                  error: getFormError(response, 'end_datetime'),
                }}
              />
            </div>
          </div>

          <div>
            <label>
              {isProfile ? i18next.t('Profile') : i18next.t('Products')}
            </label>
            <div className="form-item">
              <ActivityProductVersions
                isProfile={isProfile}
                activityType={type}
                organisation={selectedOrganisation.update(
                  'pure_products',
                  products =>
                    products && products.map(product => product.get('id'))
                )}
                activityProductVersions={
                  values.productOrganisations &&
                  values.productOrganisations.map(
                    buildFakeActivityProductVersion
                  )
                }
                onAdd={addedProductOrganisations =>
                  setProductOrganisations(
                    values.productOrganisations.concat(
                      addedProductOrganisations
                    )
                  )
                }
                onRemove={removedId =>
                  setProductOrganisations(
                    values.productOrganisations.filter(
                      each => each.get('id') !== removedId
                    )
                  )
                }
                noLoad
                numRespondents={values.userIds.count()}
              />
              <FormError response={response} formKey="product_organisations" />
            </div>
          </div>

          {isProfile && showProfileName && (
            <div>
              <label htmlFor="profile_name">{i18next.t('Profile name')}</label>
              <div className="form-item">
                <Field
                  id="profile_name"
                  name="profile_name"
                  type="text"
                  placeholder={i18next.t(
                    'Name of role / level / critical talent pool'
                  )}
                  onChange={e =>
                    setFieldValue('profile', { profile_name: e.target.value })
                  }
                />
                <FormError response={response} formKey="profile" />
              </div>
              <div className="tooltip">
                <span data-tip data-for="job-role-tooltip">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="job-role-tooltip"
                  textColor="black"
                  backgroundColor="white"
                >
                  {i18next.t(
                    'This is the name that will appear in the reports and profile text.'
                  )}
                </ReactTooltip>
              </div>
            </div>
          )}

          {values.productOrganisations.some(productOrganisation =>
            productOrganisation.get('allow_staged')
          ) && (
            <div>
              <label>{i18next.t('Staged assessment')}</label>
              <div className="form-item">
                <Checkbox
                  id="is_staged"
                  name="is_staged"
                  label={i18next.t('Is staged')}
                  checked={values.is_staged}
                  onChange={evt =>
                    setFieldValue('is_staged', evt.target.checked)
                  }
                />
                <FormError response={response} formKey="is_staged" />
              </div>
            </div>
          )}

          {type === ACTIVITY_TYPE.PSYCHOMETRIC && !isProfile && (
            <div>
              <label>{i18next.t('Activity sent via')}</label>
              <div className="form-item stacked">
                {this.getInvitationTypes().map(invitationType => {
                  const viaLinkSelected =
                    invitationIsViaLink &&
                    !isProfile &&
                    values.invitation_type === invitationType;
                  const showQuantityDropdown =
                    viaLinkSelected && hasSelectedProduct && !isProfile;
                  const showAlert =
                    viaLinkSelected && maxNumUsers !== null && !isProfile;
                  return (
                    <div
                      key={invitationType}
                      className={classNames(
                        'full-width radio-quantity-dropdown',
                        {
                          'form-old-radiobutton-wrapper': !showQuantityDropdown,
                        }
                      )}
                    >
                      <RadioButton
                        id={invitationType}
                        name="invitation_type"
                        onChange={evt =>
                          setFieldValue('invitation_type', evt.target.value)
                        }
                        value={invitationType}
                        checked={values.invitation_type === invitationType}
                        label={INVITATION_TYPES.DISPLAY[invitationType]}
                      >
                        {showQuantityDropdown && (
                          <QuantityDropdown
                            value={values.invitation_link_usage_limit}
                            maxQuantity={
                              maxNumUsers === null ? undefined : maxNumUsers
                            }
                            maxDropdownValue={10}
                            onChange={value => {
                              setFieldValue(
                                'invitation_link_usage_limit',
                                value
                              );
                            }}
                            response={response}
                            responseFieldName="invitation_link_usage_limit"
                            fieldName="invitation_link_usage_limit"
                            errorKey="invitation_link_usage_limit"
                            hasUnlimited={maxNumUsers === null}
                          />
                        )}
                      </RadioButton>
                      {showAlert && (
                        <Alert className="mt-sm">
                          {i18next.t(
                            'You have {{count}} {{productName}} available. Select how many',
                            { count: maxNumUsers, productName }
                          )}
                        </Alert>
                      )}
                    </div>
                  );
                })}
                <FormError response={response} formKey="invitation_type" />
              </div>
            </div>
          )}

          {invitationIsViaEmail && (
            <div>
              <label>
                {isProfile
                  ? i18next.t('Profile raters')
                  : i18next.t('Respondents')}
              </label>
              <div className="form-item">
                <CreateActivityAddUsers
                  userIds={values.userIds}
                  onUpdate={userIds => setFieldValue('userIds', userIds)}
                  organisation={selectedOrganisation}
                  maxNumUsers={maxNumUsers}
                  isProfile={isProfile}
                />
                {maxNumUsers !== null && !isProfile && (
                  <Alert>
                    {i18next.t('You can add up to {{count}} respondents', {
                      count: maxNumUsers,
                    })}
                  </Alert>
                )}
              </div>
            </div>
          )}

          {is360 && (
            <div>
              <div className="form-label">
                {i18next.t('Line manager approval')}
              </div>
              <div className="form-item">
                <Checkbox
                  id="line_manager_must_approve_raters"
                  name="line_manager_must_approve_raters"
                  label={i18next.t('Required')}
                  checked={values.line_manager_must_approve_raters}
                  onChange={evt =>
                    setFieldValue(
                      'line_manager_must_approve_raters',
                      evt.target.checked
                    )
                  }
                />
              </div>
            </div>
          )}
          {values.productOrganisations.size <= 1 && canPulse && (
            <div className="hide-input">
              <Checkbox
                id="with_pulse"
                name="with_pulse"
                checked={values.with_pulse}
                onChange={evt =>
                  setFieldValue('with_pulse', evt.target.checked)
                }
              />
            </div>
          )}
          <div>
            <label>{i18next.t('Report options')}</label>
            {productVariant ? (
              <ReportGenerationToggle
                readOnly
                reportGeneration={REPORT_GENERATION.CHOICES.AUTO}
                disabledMessage={i18next.t(
                  'This product requires auto report generation.'
                )}
              />
            ) : (
              <ReportGenerationToggle
                is360={is360}
                reportGeneration={values.reportGeneration}
                onChange={value => setFieldValue('reportGeneration', value)}
                readOnly={values.productOrganisations.isEmpty()}
                disabledMessage={i18next.t(
                  'You must select a product before you can select auto report option'
                )}
              />
            )}
          </div>
        </div>

        {!values.productOrganisations.isEmpty() &&
          values.reportGeneration === REPORT_GENERATION.CHOICES.AUTO && (
            <div className="mt-lg">
              <ProductVersionsReportGenerationSubform
                organisation={selectedOrganisation}
                activityProductVersions={values.productOrganisations.map(
                  buildFakeActivityProductVersion
                )}
                values={values}
                response={response}
                setFieldValue={setFieldValue}
              />

              {selectedOrganisation.get('is_using_credit') &&
                !productVariant &&
                invitationTypeShowsCost && (
                  <ReportCost
                    hidePurchaseLink
                    organisation={selectedOrganisation}
                    numberOfRespondents={
                      invitationIsViaEmail
                        ? values.userIds.count()
                        : values.invitation_link_usage_limit
                    }
                    reportTemplatesByProductOrganisation={values.productOrganisations.map(
                      productOrganisation => [
                        productOrganisation,
                        getSelectedReportTemplates(productOrganisation, values),
                      ]
                    )}
                  />
                )}

              <ReportDistribution
                activity={fromJS({
                  notifications: [],
                  additional_report_recipients,
                  additional_report_recipients_send_date,
                  send_zipped_reports_to_activity_creator,
                  end_datetime,
                  created_by_user: user,
                })}
                editNotification={() =>
                  this.editNotification(
                    reportEmailDefaults,
                    values.notifications,
                    'ACTIVITY_REPORTS_READY',
                    'ACTIVITY_REPORTS_READY'
                  )
                }
                editReportNotification={(default_template, template_type) =>
                  this.editNotification(
                    reportEmailDefaults,
                    values.notifications,
                    default_template,
                    template_type
                  )
                }
                updateFields={data => this.updateFields(data, setFieldValue)}
                canUserMuteAdminReportDistribution={
                  canUserMuteAdminReportDistribution
                }
                readOnly={false}
                response={Map()}
                is360={is360}
                isProfile={isProfile}
                user={user}
                reportVisibility={Map({
                  rule: report_visibility_rule,
                  date: report_visibility_date,
                })}
                setDisplay={rule =>
                  setFieldValue('report_visibility_rule', rule)
                }
                setDate={date => setFieldValue('report_visibility_date', date)}
                updateReportVisibilityResponse={Map()}
              />
            </div>
          )}
      </div>
    );
  }
}

export class CreateActivityForm extends PureComponent {
  isReadyToSubmit(values) {
    return isReadyToSubmit(Object.assign(values, { type: this.props.type }));
  }

  render() {
    const { onSubmit, response, initialValues } = this.props;

    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form className="create-activity-form">
            <CreateActivityFormDetails
              values={values}
              setFieldValue={setFieldValue}
              {...this.props}
            />

            <div className="form-button-group">
              <hr />
              <div className="form-item wrapping">
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={this.props.onClose}
                >
                  {i18next.t('Cancel')}
                </button>

                <LiveButton
                  disabled={!this.isReadyToSubmit(values)}
                  buttonText={i18next.t('Create activity')}
                  pendingMessage={i18next.t('Creating activity...')}
                  response={response}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export function mapStateToProps(state) {
  const user = state.userProfile;
  return {
    isModalOpen: Boolean(state.ui.get('modalsConfig').length),
    canAdminOrgs: can(user, administerOrganisations()),
    selectedProductOrganisationsPulses: state.ui
      .get('selectedUiComponents')
      .get('productOrganisationsAddPulse', new Set()),
    canUserMuteAdminReportDistribution: can(
      user,
      muteAdminReportDistribution()
    ),
    user,
    clearSelection: clearSelection,
  };
}

const ConnectedCreateActivityForm = connect(mapStateToProps, {
  openEmailEditModal,
  closeTopModal,
  clearCollection,
  clearSelection,
})(CreateActivityForm);

export default ConnectedCreateActivityForm;
