import i18next from 'i18next';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { toggleReportsTableSelection } from '^/actions/actions';
import { loadOrganisation } from '^/actions/items';
import { openCreateJobMatchReportsModal } from '^/actions/modals';
import Table from '^/components/Table';
import {
  ProfileSessions,
  ProfileSession,
  OrgSessions,
  OrgUser,
  Uuid,
  ProductOrganisation,
} from '^/reducers/api/types';
import {
  ReportsTableSelections,
  JobProfileSelection,
  available,
  isSelected,
  asKeys,
} from '^/reducers/reports/types';
import { selectOrgSessions, selectUserProfile } from '^/selectors';
import { StoreState } from '^/store';
import {
  selectAccountHasUnlimitedCredits,
  selectAllSelectedReportsTable,
  selectAnySelectedReportsTable,
  selectFilteredOrgUsers,
  selectHasAnyReportsTable,
  selectReportsTableSelections,
} from './selectors';
import { selectMappedSelectedProducts } from './AdminReportsPage/selectors';
import { DropdownOption } from '^/components/dropdown/DropdownItem';
import NewButton from '^/components/NewButton';
import { can, downloadExistingReports } from '^/capabilities';
import SelectableReport from './SelectableReport';

type OwnProps = {
  profileSessions: ProfileSessions;
  canUserAdministerOwnOrganisation: boolean;
  orgId: Uuid | null;
  orgSession: ProductOrganisation | null;
  templateJMAvailable: boolean;
};

interface DispatchProps {
  toggleReportsTableSelection: typeof toggleReportsTableSelection;
  loadOrganisation: typeof loadOrganisation;
  openCreateJobMatchReportsModal: typeof openCreateJobMatchReportsModal;
}

interface StateProps {
  user: Immutable.Map<string, any>;
  orgSessions: OrgSessions | null;
  users: ReadonlyArray<OrgUser>;
  accountHasUnlimitedCredits: boolean;
  reportsTableSelections: ReportsTableSelections;
  allSelected: { [key in keyof ReportsTableSelections]: boolean };
  anySelected: { [key in keyof ReportsTableSelections]: boolean };
  hasAny: { [key in keyof ReportsTableSelections]: boolean };
  productsItems: ReadonlyArray<DropdownOption>;
  organisation: Immutable.Map<string, any>;
}

export type Props = OwnProps & StateProps & DispatchProps;

interface State {
  noAvailableSessions: boolean;
}

export class ProfileReportsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const state = {
      noAvailableSessions: false,
    };

    if (props.canUserAdministerOwnOrganisation) {
      state.noAvailableSessions = false;
    }

    this.state = state;
  }

  fetchDataForCurrentlySelectedOrganisation() {
    this.props.loadOrganisation(this.props.orgId);
  }

  UNSAFE_componentWillMount(): void {
    this.fetchDataForCurrentlySelectedOrganisation();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.orgId !== this.props.orgId) {
      this.fetchDataForCurrentlySelectedOrganisation();
    }
  }

  public render() {
    const { orgSession, user } = this.props;
    const profileSessions = this.props.profileSessions;
    return (
      <div className="profile-reports-table">
        <p className="mb-md mt-sm" />
        {profileSessions && this.getReportCountMessage(profileSessions.length)}
        {profileSessions && orgSession && (
          <Table
            className="interactive-table"
            columns={[
              {
                header: i18next.t<string>('Profile name'),
                value: session =>
                  session.activity_product_version.activity.profile_name,
              },
              {
                header: i18next.t<string>('Date created'),
                value: session => this.renderDate(session.completed),
              },
              {
                header: i18next.t<string>('Profile raters'),
                value: session => session.user.full_name,
              },
              {
                header: i18next.t<string>('Report'),
                value: report =>
                  this.renderSelectableReport({
                    report: (report.reports && report.reports[0]) || undefined,
                    reportTemplate: {
                      ...orgSession.productorganisationreporttemplate_set[0]
                        .report_template,
                      code:
                        orgSession.product.code +
                        ' - ' +
                        orgSession.productorganisationreporttemplate_set[0]
                          .report_template.code,
                    },
                    productId: orgSession.product.id,
                    productVariantId: null,
                    userId: user.toObject().id,
                    sessionId: report.id,
                    mine: user.toObject().mine,
                    sharedWithMe: user.toObject().sharedWithMe,
                    completed: report.completed,
                    roleName:
                      report.activity_product_version.activity.profile_name,
                  }),
              },
              {
                header: 'Action',
                value: session => this.renderActionButton(session),
              },
            ]}
            rows={List(profileSessions)}
            emptyMessage={i18next.t<string>(
              'There are no Job Profile Reports created yet for this account.'
            )}
          />
        )}
      </div>
    );
  }
  private renderActionButton(session: ProfileSession) {
    const id = session.id;
    const isReportGenerated = session.reports.length > 0;
    const roleName = session.activity_product_version.activity.profile_name;
    return (
      <NewButton
        buttonType="form"
        onClick={() =>
          this.openJobMatchReportsModal(id, isReportGenerated, roleName)
        }
        className="ml-none"
        disabled={!this.props.templateJMAvailable}
        title={
          !this.props.templateJMAvailable
            ? i18next.t<string>(
                'You do not have access to the Job Match report'
              )
            : ''
        }
      >
        {i18next.t<string>('Create Job Match')}
      </NewButton>
    );
  }
  private renderDate(date: string) {
    return date ? moment(date).format('Do MMM, YYYY') : '';
  }

  private openJobMatchReportsModal = (
    id: string,
    isReportGenerated: boolean,
    roleName: string
  ) => {
    const { orgId, orgSessions, orgSession } = this.props;
    const jobProfileReportTemplate =
      orgSession?.productorganisationreporttemplate_set[0].report_template;

    if (orgSessions && jobProfileReportTemplate) {
      this.props.openCreateJobMatchReportsModal(
        id,
        orgId || '',
        jobProfileReportTemplate,
        isReportGenerated,
        roleName
      );
    }
  };

  private getSelectionState(selection: JobProfileSelection) {
    const [key, innerKey] = asKeys(selection);
    return this.props.reportsTableSelections[key][innerKey];
  }

  private renderSelectableReport(selection: JobProfileSelection) {
    const { user } = this.props;
    const userCanDownloadExistingReports = can(user, downloadExistingReports());
    const selectionState = this.getSelectionState(selection);
    const hasVariant = selection.productVariantId !== null;
    return (
      <SelectableReport
        completed={selection.completed}
        key={(selection.report && selection.report.id) || '1'}
        label={
          (selection.reportTemplate && selection.reportTemplate.code) || ''
        }
        status={(selection.report && selection.report.pdf_status) || undefined}
        disabled={
          userCanDownloadExistingReports &&
          this.props.anySelected[
            selection.report ? 'reportTemplates' : 'reports'
          ]
        }
        isUnavailable={
          !available(selectionState) ||
          (!userCanDownloadExistingReports && hasVariant)
        }
        extraClass={'profile-btn'}
        hasVariant={hasVariant}
        downloadBlocked={!userCanDownloadExistingReports}
        isSelected={isSelected(selectionState)}
        onSelect={this.props.toggleReportsTableSelection.bind(null, selection)}
        roleName={selection.roleName}
        reportName={selection.reportTemplate.name}
      />
    );
  }

  private getReportCountMessage(ReportsCount: number) {
    if (ReportsCount === 0) {
      return i18next.t<string>('No reports');
    }
    return i18next.t<string>('Showing {{count}} report', {
      count: ReportsCount,
    });
  }
}

export function mapStateToProps(state: StoreState): StateProps {
  return {
    user: selectUserProfile(state),
    users: selectFilteredOrgUsers(state),
    orgSessions: selectOrgSessions(state),
    accountHasUnlimitedCredits: selectAccountHasUnlimitedCredits(state),
    reportsTableSelections: selectReportsTableSelections(state),
    allSelected: selectAllSelectedReportsTable(state),
    anySelected: selectAnySelectedReportsTable(state),
    hasAny: selectHasAnyReportsTable(state),
    productsItems: selectMappedSelectedProducts(state),
    organisation: state.items.get('organisations'),
  };
}

export default connect(mapStateToProps, {
  toggleReportsTableSelection,
  loadOrganisation,
  openCreateJobMatchReportsModal,
})(ProfileReportsTable);
