import React from 'react';
import moment, { Moment } from 'moment';
import { connect } from 'react-redux';

import {
  getAllOrgs,
  clearOrgFilters,
  clearPoll,
  filterHiddenReportsTableSelection,
} from '^/actions/actions';
import { loadOrgProductsThenProfileSessions } from '^/actions/actionSequences';
import {
  clearReportFilterSelection,
  ReportFilterSelections,
} from '^/actions/ui';
import {
  administerOrganisations,
  administerOwnOrganisation,
} from '^/capabilities';
import CollapsibleSidebar from '^/components/CollapsibleSidebar';
import { selectReportTableIsLoading } from '^/components/reports/admin/AdminReportsPage/selectors';
import {
  UserSessions,
  selectAnySelectionReportsTable,
  selectFilteredOrgUsers,
  selectSessionsByUser,
} from '^/components/reports/admin/selectors';
import {
  AllOrgs,
  Uuid,
  OrgSessions,
  ProfileSessions,
  OrgUser,
} from '^/reducers/api/types';
import {
  selectAllOrgs,
  selectOrgSessions,
  selectUserProfile,
} from '^/selectors';
import { selectUserCapability, selectUserOrgId } from '^/selectors/user';
import { StoreState } from '^/store';
import {
  selectReportFilterSelection,
  selectReportsInitialOrganisation,
} from '^/selectors/ui';
import ProfileReportsTable from './ProfileReportsTable';
import ReportsActionBar from './ReportsActionBar';
import ProfileReportsFilters from './ProfileReportsFilters';
import { ACTIVITY_TYPE } from '^/models/activity';
import { REPORT_TEMPLATE_CODES } from '^/models/report';

const filterSessionsByDate = (
  sessions: ProfileSessions,
  from: moment.MomentInput | undefined,
  to: moment.MomentInput | undefined
) => {
  return from && to
    ? sessions.filter(item =>
        moment(moment(new Date(item.completed))).isBetween(from, to)
      )
    : sessions;
};

const filterSessionsByActivity = (
  sessions: ProfileSessions,
  activities: string[]
) => {
  return sessions && activities.length
    ? sessions.filter(item =>
        activities.includes(item.activity_product_version.activity.id)
      )
    : sessions;
};

interface OwnProps {
  switcher?: React.ReactNode;
}

interface StateProps {
  profileSessions: ProfileSessions;
  user: Immutable.Map<string, any>;
  users: ReadonlyArray<OrgUser>;
  hasAnySelection: boolean;
  isLoadingTable: boolean;
  organisations: AllOrgs;
  sessionsByUser: UserSessions;
  orgSessions: OrgSessions | null;
  userOrgId?: Uuid;
  initialOrganisation: Uuid | null;
  canUserAdministerOwnOrganisation: boolean;
  canUserAdministerOrganisations: boolean;
  reportFilterSelection: ReportFilterSelections;
}

interface DispatchProps {
  getAllOrgs: typeof getAllOrgs;
  clearOrgFilters: typeof clearOrgFilters;
  clearReportFilterSelection: typeof clearReportFilterSelection;
  loadOrgProductsThenProfileSessions: typeof loadOrgProductsThenProfileSessions;
  filterHiddenReportsTableSelection: typeof filterHiddenReportsTableSelection;
  clearPoll: typeof clearPoll;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class ProfileReportsPage extends React.Component<Props> {
  public readonly state = {
    orgId: null,
    selectedDate: {
      from: undefined,
      to: undefined,
    },
  };

  public componentDidMount() {
    const { userOrgId, initialOrganisation } = this.props;
    if (initialOrganisation) {
      this.loadOrganisation(initialOrganisation);
    } else if (this.props.canUserAdministerOwnOrganisation && userOrgId) {
      this.loadOrganisation(userOrgId);
    } else {
      this.props.getAllOrgs({ profile: true });
    }
  }

  public componentWillUnmount() {
    this.props.clearOrgFilters();
    this.props.clearPoll('ProfileReportsPage');
  }

  public render() {
    const {
      organisations,
      canUserAdministerOrganisations,
      switcher,
      isLoadingTable,
      orgSessions,
      hasAnySelection,
      initialOrganisation,
    } = this.props;
    const { orgId, selectedDate } = this.state;
    const OrgSession =
      orgSessions &&
      orgSessions.productorganisation_set.filter(
        template => template.product.activity_type === ACTIVITY_TYPE.PROFILER
      )[0];

    const reportTemplates =
      orgSessions &&
      orgSessions.productorganisation_set.map(items =>
        items.productorganisationreporttemplate_set.map(
          item => item.report_template
        )
      );

    const templateCodes = reportTemplates?.map(items =>
      items.map(item => item.code)
    );
    const templateJMAvailable =
      templateCodes &&
      templateCodes
        .map(items => items.includes(REPORT_TEMPLATE_CODES.JOB_MATCH))
        .includes(true);
    const profileSessions = this.getFilteredProfileSessions();
    return (
      <div className="report-table-and-filters-wrapper">
        <CollapsibleSidebar>
          <ProfileReportsFilters
            initialOrganisation={initialOrganisation}
            canUserAdministerOrganisations={canUserAdministerOrganisations}
            organisations={organisations}
            onOrganisationClick={this.onOrganisationClick}
            isLoadingTable={isLoadingTable}
            hasOrgSelected={orgId !== null}
            selectedDate={selectedDate}
            setDate={this.setSelectedDate}
          />
        </CollapsibleSidebar>

        <div className="report-type-switcher">{switcher}</div>

        <div className="report-table-wrapper">
          {orgId && orgSessions && (
            <>
              <ProfileReportsTable
                profileSessions={profileSessions}
                orgId={orgId}
                templateJMAvailable={templateJMAvailable || false}
                canUserAdministerOwnOrganisation={
                  canUserAdministerOrganisations
                }
                orgSession={OrgSession}
              />
              <ReportsActionBar
                isProfiler
                visible={hasAnySelection}
                organisation={orgSessions}
                orgId={orgId!}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  private getFilteredProfileSessions() {
    const {
      selectedDate: { from, to },
    } = this.state;
    const sessionsFilteredByDate = filterSessionsByDate(
      this.props.profileSessions,
      from,
      to
    );

    const { reportFilterSelection } = this.props;
    const activityIds = Object.entries(reportFilterSelection.activities)
      .map(([activityId, selected]) => (selected ? activityId : null))
      .filter(activityId => activityId !== null) as string[];
    return filterSessionsByActivity(sessionsFilteredByDate, activityIds);
  }

  private setSelectedDate = (fieldName: string, date?: Moment) => {
    this.setState({
      selectedDate: {
        ...this.state.selectedDate,
        [fieldName]: date ? date.toDate() : null,
      },
    });
  };

  private onOrganisationClick = (orgId: string | number | null) => {
    this.props.clearReportFilterSelection();
    this.loadOrganisation(
      typeof orgId !== 'string' && orgId !== null ? `${orgId}` : orgId
    );
  };

  private loadOrganisation = (orgId: Uuid | null) => {
    const { user } = this.props;
    if (orgId) {
      this.setState({ orgId });
      return this.props.loadOrgProductsThenProfileSessions(orgId, user);
    }
    return Promise.reject();
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    user: selectUserProfile(state),
    users: selectFilteredOrgUsers(state),
    profileSessions: state.profileSessions,
    hasAnySelection: selectAnySelectionReportsTable(state),
    sessionsByUser: selectSessionsByUser(state),
    initialOrganisation: selectReportsInitialOrganisation(state),
    isLoadingTable: selectReportTableIsLoading(state),
    userOrgId: selectUserOrgId(state),
    canUserAdministerOwnOrganisation: selectUserCapability(
      state,
      administerOwnOrganisation()
    ),
    canUserAdministerOrganisations: selectUserCapability(
      state,
      administerOrganisations()
    ),
    organisations: selectAllOrgs(state),
    orgSessions: selectOrgSessions(state),
    reportFilterSelection: selectReportFilterSelection(state),
  };
}

export default connect(mapStateToProps, {
  getAllOrgs,
  clearOrgFilters,
  clearReportFilterSelection,
  loadOrgProductsThenProfileSessions,
  filterHiddenReportsTableSelection,
  clearPoll,
})(ProfileReportsPage);
