import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Map, Set } from 'immutable';

import CreateUserInlineForm from '^/components/users/CreateUserInlineForm';
import Alert from '^/components/Alert';
import { StoreState } from '^/store';
import { addRaterUserThen } from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';

interface User {
  id: string;
  full_name: string;
  email: string;
}

interface StateProps {
  response: Map<string, any>;
}

interface DispatchProps {
  addRaterUserThen: typeof addRaterUserThen;
  closeTopModal: typeof closeTopModal;
}

interface OwnProps {
  currentIds: Set<string>;
  maxNumUsers: number | undefined;
  updateUsers: (selectedUsers: Set<string>) => void;
  setProfileCreatorEmail: (email: string) => void;
  organisationId: string;
}

type Props = StateProps & DispatchProps & OwnProps;

const AddProfileRatersModal = (props: Props) => {
  const addUpdateUsersAndClose = (user: User) => {
    const userId = user.id;
    const email = user.email;
    if (!props.currentIds.includes(userId)) {
      const users = props.currentIds.add(userId);
      props.updateUsers(users);
      props.setProfileCreatorEmail(email);
    }
    props.closeTopModal();
  };

  const addExternalUser = (values: { full_name: string; email: string }) => {
    props.addRaterUserThen(
      addUpdateUsersAndClose,
      values.email,
      values.full_name,
      true,
      false,
      true,
      props.organisationId
    );
  };

  return (
    <div>
      <p>
        Nominate the individual rater(s) who will be invited to input into the
        success profile.
      </p>
      <CreateUserInlineForm
        response={props.response}
        onSubmit={addExternalUser}
      />
      <Alert className="mt-sm">
        {
          i18next.t(
            'Please enter the full name and email address'
          ) as React.ReactNode
        }
      </Alert>
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    selectedUsers: state.ui.get('selectedUiComponents').get('users', Set()),
    response: state.responses.get('addToCollection'),
    orgFilters: state.orgFilters,
    users: state.collections.get('users', Map()),
  };
}

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  { addRaterUserThen, closeTopModal }
)(AddProfileRatersModal);
