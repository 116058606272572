/* eslint-disable @typescript-eslint/no-use-before-define */
import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import {
  loadDiscSessions,
  setModalTitle,
  loadOrgFilters,
} from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import { generateJobMatchReportsAndRefresh } from '^/actions/actionSequences';
import { can, administerOwnOrganisation } from '^/capabilities';
import { LanguageCode } from '^/constants/routes';
import { STAGES } from '^/components/reports/admin/ReportActions';
import {
  OrgFilters,
  OrgSessions,
  DiscSessions,
  Uuid,
  ReportTemplate,
} from '^/reducers/api/types';
import { StoreState } from '^/store';
import {
  getJobMatchReportTemplates,
  getSessionsMissingReportTemplate,
} from '^/components/analytics/CreateTeamReportModal/utils';
import ChooseSessionsPage from '^/components/analytics/CreateTeamReportModal/ChooseSessionsPage';
import ConfirmPurchasePage from '^/components/analytics/CreateTeamReportModal/ConfirmPurchasePage';
import ReportActionsPage from '^/components/analytics/CreateTeamReportModal/ReportActionsPage';
import {
  ReportToGenerate,
  TeamReportModalPage,
} from '^/components/analytics/CreateTeamReportModal/types';

interface State {
  page: TeamReportModalPage;
  formStage: string;
  selectedReports: Array<{ reportId: Uuid }>;
  teamName: string;
  selectedSessionIds: Set<Uuid>;
  languageCode: LanguageCode;
  reportsToGenerate: ReportToGenerate[];
}

interface OwnProps {
  profileSessionId: Uuid;
  orgId: Uuid;
  jobProfileReportTemplate: ReportTemplate;
  isJobProfileReportGenerated: boolean;
  roleName: string;
}

interface StateProps {
  user: Immutable.Map<string, any>;
  discSessions: DiscSessions | null;
  orgFilters: OrgFilters | null;
  orgSessions: OrgSessions | null;
  canUserAdministerOwnOrganisation: boolean;
  response: Immutable.Map<string, any> | undefined;
  productId: Uuid | null;
}

interface DispatchProps {
  loadDiscSessions: typeof loadDiscSessions;
  closeTopModal: typeof closeTopModal;
  setModalTitle: typeof setModalTitle;
  generateJobMatchReportsAndRefresh: typeof generateJobMatchReportsAndRefresh;
  loadOrgFilters: typeof loadOrgFilters;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class CreateJobMatchReportsModal extends React.Component<Props, State> {
  public readonly state = {
    page: TeamReportModalPage.ChooseSessions,
    formStage: STAGES.SET_UP,
    teamName: '',
    selectedSessionIds: new Set<Uuid>(),
    selectedReports: [],
    languageCode: LanguageCode.EN_GB,
    reportsToGenerate: [],
  } as State;

  public componentDidMount() {
    if (this.props.productId) {
      this.setState({
        reportsToGenerate: getJobMatchReportTemplates(
          this.props.orgSessions,
          this.props.productId,
          this.getSelectedSessions.bind(this),
          this.props.jobProfileReportTemplate,
          this.props.isJobProfileReportGenerated
        ),
      });
    }
    if (this.props.orgId) {
      this.props.loadOrgFilters(this.props.orgId);
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.discSessions !== this.props.discSessions ||
      prevState.languageCode !== this.state.languageCode ||
      prevProps.productId !== this.props.productId
    ) {
      if (this.props.productId) {
        this.setState({
          reportsToGenerate: getJobMatchReportTemplates(
            this.props.orgSessions,
            this.props.productId,
            this.getSelectedSessions.bind(this),
            this.props.jobProfileReportTemplate,
            this.props.isJobProfileReportGenerated
          ),
        });
      }
    }
  }

  public componentWillUnmount(): void {
    this.props.loadOrgFilters(this.props.orgId, { profile: true });
  }

  private getSelectedSessions() {
    const { selectedSessionIds } = this.state;
    const { discSessions } = this.props;
    return (discSessions || []).filter(session =>
      selectedSessionIds.has(session.id)
    );
  }

  private updateSelectedSessions = (selectedSessionIds: Set<Uuid>) => {
    this.setState({ selectedSessionIds: new Set(selectedSessionIds) });
  };

  private updateReportsToGenerate = (reportsToGenerate: ReportToGenerate[]) => {
    this.setState({ reportsToGenerate });
  };

  private updateFormStage = (formStage: string) => {
    this.setState({ formStage });
  };

  private updateSelectedReports = (reports: ReportToGenerate[]) => {
    this.setState({
      selectedReports: reports.map((each: any) => ({ reportId: each.id })),
    });
  };

  private updateLanguageCode = (languageCode: LanguageCode) => {
    this.setState({ languageCode });
  };

  private moveToConfirmPurchasePage = () => {
    this.props.setModalTitle(i18next.t<string>('Confirm purchase'));
    if (this.props.productId) {
      this.setState({
        page: TeamReportModalPage.ProvideName,
        reportsToGenerate: getJobMatchReportTemplates(
          this.props.orgSessions,
          this.props.productId,
          this.getSelectedSessions.bind(this),
          this.props.jobProfileReportTemplate,
          this.props.isJobProfileReportGenerated
        ),
      });
    }
  };

  private generate = () => {
    const { reportsToGenerate, selectedSessionIds } = this.state;
    const { profileSessionId, jobProfileReportTemplate } = this.props;

    const jobMatchReports = Array.from(selectedSessionIds).map(
      (session: string) => ({
        report_template: reportsToGenerate[0].id,
        session: session,
      })
    );

    const jobMatchRelatedReports = reportsToGenerate
      .filter(report => report.isJobMatchRelated && report.selected)
      .flatMap(report => {
        const selectedSessionsMissingReport = getSessionsMissingReportTemplate(
          report.id,
          this.getSelectedSessions()
        );
        return selectedSessionsMissingReport.map(session => ({
          report_template: report.id,
          session: session.id,
        }));
      });

    const jobProfileReport = reportsToGenerate
      .filter(
        report => report.id === jobProfileReportTemplate.id && report.selected
      )
      .map(report => ({
        report_template: report.id,
        session: profileSessionId,
      }));

    const reports = [
      ...jobMatchReports,
      ...jobMatchRelatedReports,
      ...jobProfileReport,
    ];

    this.props.generateJobMatchReportsAndRefresh(
      {
        reports_to_generate: reports,
        job_profile_session: profileSessionId,
      },
      this.props.orgId,
      (generatedReports: any) => {
        this.updateSelectedReports(generatedReports);
        this.updateFormStage(STAGES.GENERATING);
      }
    );
  };

  public render() {
    const {
      page,
      formStage,
      selectedSessionIds,
      reportsToGenerate,
      languageCode,
      selectedReports,
    } = this.state;
    const {
      orgId,
      productId,
      orgSessions,
      orgFilters,
      discSessions,
      canUserAdministerOwnOrganisation,
      response,
      profileSessionId,
      roleName,
    } = this.props;

    if (formStage === STAGES.SET_UP) {
      return page === TeamReportModalPage.ChooseSessions ? (
        <ChooseSessionsPage
          orgId={orgId}
          productId={productId}
          profileSessionId={profileSessionId}
          moveToConfirmPurchasePage={this.moveToConfirmPurchasePage}
          selectedSessionIds={selectedSessionIds}
          updateSelectedSessions={this.updateSelectedSessions}
          orgSessions={orgSessions}
          sessions={discSessions}
          orgFilters={orgFilters}
          canUserAdministerOwnOrganisation={canUserAdministerOwnOrganisation}
          loadSessions={this.props.loadDiscSessions}
        />
      ) : (
        <ConfirmPurchasePage
          productId={productId}
          reportsToGenerate={reportsToGenerate}
          orgSessions={orgSessions}
          selectedSessionIds={selectedSessionIds}
          languageCode={languageCode}
          getSelectedSessions={this.getSelectedSessions.bind(this)}
          updateReportsToGenerate={this.updateReportsToGenerate}
          updateLanguageCode={this.updateLanguageCode}
          response={response}
          generate={this.generate}
          isTeamReports={false}
          roleName={roleName}
          isJobMatch
        />
      );
    }
    return (
      <ReportActionsPage
        orgId={orgId}
        formStage={formStage}
        updateFormStage={this.updateFormStage}
        selectedReports={selectedReports}
        isTeamReport={false}
        isJobMatch
      />
    );
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    user: state.userProfile,
    discSessions: state.discSessions,
    orgFilters: state.orgFilters,
    orgSessions: state.orgSessions,
    canUserAdministerOwnOrganisation: can(
      state.userProfile,
      administerOwnOrganisation()
    ),
    response: state.responses.get('generateTeamReport'),
    productId:
      state.discSessions?.length > 0
        ? state.discSessions[0].activity_product_version.product_version.product
            .id
        : null,
  };
}

export default connect(mapStateToProps, {
  loadDiscSessions,
  closeTopModal,
  setModalTitle,
  generateJobMatchReportsAndRefresh,
  loadOrgFilters,
})(CreateJobMatchReportsModal);
