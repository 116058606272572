import React from 'react';
import i18next from 'i18next';
import { List } from 'immutable';

import Alert, { AlertType } from '^/components/Alert';
import Table, { Columns } from '^/components/Table';
import { ReportToGenerate } from './types';
import Checkbox from '../../Checkbox';

interface Props {
  reportsToGenerate: ReportToGenerate[];
  updateReportsToGenerate: (reports: ReportToGenerate[]) => void;
  isTeamReports: boolean;
  isJobMatch: boolean;
  roleName?: string;
}

export const SelectReportsTable: React.FC<Props> = props => {
  function selectAll() {
    const filterReports = !props.isJobMatch
      ? props.reportsToGenerate.filter(i => i.isJobMatch === false)
      : props.reportsToGenerate;
    props.updateReportsToGenerate(
      filterReports.map(report => ({
        ...report,
        selected: true,
      }))
    );
  }

  function deselectAll() {
    props.updateReportsToGenerate(
      props.reportsToGenerate.map(report => ({
        ...report,
        selected: report.isTeam || report.quantity === 0,
      }))
    );
  }

  function getSelectColumn(): Columns<any> {
    const { reportsToGenerate, isJobMatch } = props;
    const filterReports = !isJobMatch
      ? reportsToGenerate.filter(report => report.isJobMatch === false)
      : reportsToGenerate;

    return filterReports.length > 2 || isJobMatch
      ? [
          {
            header: (
              <Checkbox
                id="select-all-reports"
                checked={filterReports.every(report => report.selected)}
                disabled={filterReports.length <= 2 || isJobMatch}
                onChange={event => {
                  event.persist();
                  event.target.checked ? selectAll() : deselectAll();
                }}
              />
            ),
            value: template => (
              <Checkbox
                id={`select-${template.id}`}
                checked={template.selected}
                disabled={template.isTeam || template.isJobMatch}
                onChange={event => {
                  event.persist();
                  props.updateReportsToGenerate(
                    filterReports.map(report => {
                      return report.id === template.id
                        ? {
                            ...report,
                            selected: event.target.checked,
                          }
                        : report;
                    })
                  );
                }}
              />
            ),
          },
        ]
      : [];
  }

  function getJobRoleColumn(): Columns<any> {
    return props.isJobMatch && props.roleName
      ? [
          {
            header: i18next.t<string>('Profile name'),
            value: () => props.roleName,
          },
        ]
      : [];
  }

  const { reportsToGenerate, isTeamReports, isJobMatch } = props;
  const filterReports = !isJobMatch
    ? reportsToGenerate.filter(report => report.isJobMatch === false)
    : reportsToGenerate;
  return (
    <>
      <Table
        className="table-smaller"
        columns={[
          ...getSelectColumn(),
          {
            header: i18next.t<string>('Selected report'),
            value: template => template.name,
            className: 'uppercase',
          },
          ...getJobRoleColumn(),
          {
            header: i18next.t<string>('Quantity'),
            value: template => template.quantity,
            headerClassName: 'align-right',
            className: 'align-right',
          },
          {
            header: i18next.t<string>('Cost'),
            value: template => template.cost * template.quantity,
            headerClassName: 'align-right',
            className: 'align-right',
          },
        ]}
        rows={List(filterReports.filter(report => report.quantity !== 0))}
      />
      {isTeamReports &&
        filterReports.length > 2 &&
        filterReports.every(report => report.quantity > 0) && (
          <Alert type={AlertType.Info}>
            {i18next.t<string>(
              'To generate a team report you must also select additional report(s) for some respondents'
            )}
          </Alert>
        )}
    </>
  );
};

export default SelectReportsTable;
