import React from 'react'; // eslint-disable-line no-unused-vars
import { List, Map, Set } from 'immutable';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';
import { Link } from 'react-router';
import _ from 'underscore';

import { isPending } from '^/responseStates';
import { isTruthy } from '^/utils';
import { RULES } from '^/reportVisibility';
import { ACTIVITY_TYPE } from '^/models/activity';
import {
  getReportEmailDefaults,
  setFormStage,
  resetFormStage,
  setSimpleComponentState,
  clearSimpleComponentState,
  resetSimpleOrganisation,
  getSimpleOrganisation,
} from '^/actions/actions';
import PureComponent from '^/components/PureComponent';
import ControlBar from '^/components/ControlBar';
import Loading from '^/components/Loading';
import { addActivityAndStartEditing } from '^/actions/actionSequences';
import { clearCollection } from '^/actions/collections';
import { loadGroup } from '^/actions/items';
import CreateActivityForm, {
  buildFakeActivityProductVersion,
} from '^/components/activities/CreateActivityForm';
import CreateActivityOrgTypeForm from '^/components/activities/CreateActivityOrgTypeForm';
import {
  INVITATION_TYPES,
  getSelected,
  CREATE_ACTIVITY_STAGES,
} from '^/components/activities/CreateActivityForm/helpers';
import { getFieldsAndInitialValues } from '^/components/activities/ProductVersionsReportGenerationForm';
import { REPORT_GENERATION } from '^/components/activities/tabs/ReportGenerationToggle';
import { selectSelectedGroup } from '^/selectors/items';
import { selectResponseIsPending } from '^/selectors/responses';
import { selectReportEmailDefaults } from '^/selectors';
import { getQueryValue, getMultipleQueryValue } from '^/utils-ts';

const CREATE_ACTIVITY_FORM = 'createActivityForm';

export class CreateActivityPage extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.getReportEmailDefaults();

    if (this.props.groupId) {
      this.props.loadGroup(this.props.groupId);
      if (this.props.group) {
        const orgId = this.props.group.get('organisation');
        this.props.getSimpleOrganisation(orgId);
      }
    }

    this.reset();

    this.setState({ type: null });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.group, this.props.group)) {
      if (this.props.groupId && this.props.group) {
        const orgId = this.props.group.get('organisation');
        this.props.getSimpleOrganisation(orgId);
      }
    }
  }

  reset() {
    this.props.resetSimpleOrganisation();
    this.props.clearSimpleComponentState([
      'CreateActivityPage',
      'selectedOrganisation',
    ]);
    this.props.clearCollection('productOrganisations');
    this.props.resetFormStage(CREATE_ACTIVITY_FORM);
  }

  componentWillUnmount() {
    this.reset();
  }

  addActivity(data) {
    const [type, variant] = this.state.type.split(':');
    const productOrganisationIds = data.productOrganisations
      .map(each => each.get('id'))
      .toJS();

    const invitation_link_usage_limit =
      type === ACTIVITY_TYPE.PROFILER ? null : data.invitation_link_usage_limit;

    const baseActivityData = {
      organisation: this.props.selectedOrganisation.get('id'),
      name: data.name,
      type,
      variant,
      invitation_link_usage_limit: invitation_link_usage_limit,
      product_organisations: productOrganisationIds,
      start_datetime: data.start_datetime,
      end_datetime: data.end_datetime,
      is_prepay: data.invitation_type !== INVITATION_TYPES.CHOICES.VIA_EMAIL,
      is_anonymous:
        data.invitation_type === INVITATION_TYPES.CHOICES.VIA_ANON_LINK,
      users:
        data.invitation_type === INVITATION_TYPES.CHOICES.VIA_EMAIL
          ? data.userIds.toJS()
          : [],
      line_manager_must_approve_raters: data.line_manager_must_approve_raters,
      is_staged: data.is_staged,
      with_pulse: data.with_pulse,
    };

    if (type === ACTIVITY_TYPE.PROFILER) {
      baseActivityData.profile = data.profile;
    }

    if (data.reportGeneration === REPORT_GENERATION.CHOICES.AUTO) {
      const report_options = productOrganisationIds.map(
        productOrganisationId => {
          const {
            comparison_group,
            report_templates,
            job_levels,
          } = data.activity_product_versions[productOrganisationId];
          const jobLevel = _.values(job_levels).filter(isTruthy)[0];

          return Object.assign(
            {
              product_organisation: productOrganisationId,
              comparison_group,
              report_templates: getSelected(report_templates),
            },
            jobLevel && {
              job_level: jobLevel,
            }
          );
        }
      );

      this.props.addActivityAndStartEditing(
        Object.assign(baseActivityData, {
          report_options,
          report_visibility: {
            date: data.report_visibility_date,
            rule: data.report_visibility_rule,
          },
          send_zipped_reports_to_activity_creator:
            data.send_zipped_reports_to_activity_creator,
          additional_report_recipients_send_date:
            data.additional_report_recipients_send_date,
          additional_report_recipients: data.additional_report_recipients.toJS(),
          notifications: Object.entries(
            data.notifications.toJS()
          ).map(([key, value]) => ({ type: key, ...value })),
        })
      );
    } else {
      this.props.addActivityAndStartEditing(baseActivityData);
    }
  }

  _getFormStageHeader() {
    const { group, groupId, loadItemIsPending } = this.props;
    if (groupId) {
      if (!loadItemIsPending && group) {
        return i18next.t('Create activity for {{groupName}}', {
          groupName: group.get('name'),
        });
      }
      return '';
    }
    switch (this.props.formStage) {
      case CREATE_ACTIVITY_STAGES.ACCOUNT_TYPE_SETUP:
        return i18next.t('Create activity');
      case CREATE_ACTIVITY_STAGES.ACTIVITY_DETAILS:
        return i18next.t('Activity details');
    }
  }

  goToNextStage(type, selectedOrganisation) {
    this.props.setSimpleComponentState(
      ['CreateActivityPage', 'selectedOrganisation'],
      selectedOrganisation
    );
    this.props.setFormStage(
      CREATE_ACTIVITY_FORM,
      CREATE_ACTIVITY_STAGES.ACTIVITY_DETAILS
    );
    this.setState({ type });
  }

  renderCreateActivityOrgTypeForm() {
    const { response, groupId, defaultProductOrganisations } = this.props;

    return (
      <CreateActivityOrgTypeForm
        response={response}
        onClose={() => this.props.push(`/page/activities/view`)}
        hasSelectedGroup={!!groupId}
        goToNextStage={this.goToNextStage.bind(this)}
        groupId={groupId}
        defaultProductOrganisations={defaultProductOrganisations}
      />
    );
  }

  renderCreateActivityForm() {
    const {
      response,
      productOrganisations,
      reportEmailDefaults,
      groupId,
      group,
      selectedOrganisation,
      defaultProductOrganisations,
    } = this.props;
    const { type } = this.state;

    const activityProductVersions =
      productOrganisations &&
      productOrganisations.map(buildFakeActivityProductVersion);

    const is360 = type === ACTIVITY_TYPE.THREE_SIXTY;

    const defaultProductOrganisationObjects = productOrganisations
      ? productOrganisations.filter(each =>
          defaultProductOrganisations.includes(each.get('id'))
        )
      : List();
    const isProfile = type === ACTIVITY_TYPE.PROFILER;
    const initialValues = Object.assign(
      {
        name: '',
        start_datetime: null,
        end_datetime: null,
        productOrganisations: defaultProductOrganisationObjects,
        invitation_type:
          groupId || is360 || isProfile
            ? INVITATION_TYPES.CHOICES.VIA_EMAIL
            : INVITATION_TYPES.CHOICES.VIA_LINK,
        userIds:
          groupId && group
            ? group.get('users').map(user => user.get('id'))
            : Set(),
        notifications: Map(),
        report_visibility_rule: RULES.HIDE,
        report_visibility_date: null,
        send_zipped_reports_to_activity_creator: false,
        additional_report_recipients_send_date: null,
        additional_report_recipients: List(),
        reportGeneration: defaultProductOrganisationObjects.getIn([
          0,
          'product_variant',
        ])
          ? REPORT_GENERATION.CHOICES.AUTO
          : REPORT_GENERATION.CHOICES.MANUAL,
        line_manager_must_approve_raters: false,
        invitation_link_usage_limit:
          defaultProductOrganisationObjects.getIn([0, 'number_available']) ===
          null
            ? null
            : 0,
        is_staged: false,
        with_pulse: false,
        profile: {
          profile_name: '',
        },
      },
      getFieldsAndInitialValues(activityProductVersions, selectedOrganisation)
    );

    return (
      <CreateActivityForm
        response={response}
        onSubmit={this.addActivity.bind(this)}
        onClose={() => this.props.push(`/page/activities/view`)}
        selectedOrganisation={selectedOrganisation}
        initialValues={initialValues}
        reportEmailDefaults={reportEmailDefaults}
        hasSelectedGroup={!!groupId}
        type={type}
      />
    );
  }

  render() {
    const { response, formStage } = this.props;
    const breadcrumb = (
      <span className="breadcrumbs">
        <Link to="/page/activities/view/">{i18next.t('Activities')}</Link>
        <span>{i18next.t('Create activity')}</span>
      </span>
    );

    return (
      <div>
        <ControlBar
          hideSearch
          hideAdd
          breadcrumb={breadcrumb}
          title={this._getFormStageHeader()}
        />
        <div className="form-container">
          {isPending(response) && <Loading />}
          {formStage === CREATE_ACTIVITY_STAGES.ACCOUNT_TYPE_SETUP
            ? this.renderCreateActivityOrgTypeForm()
            : this.renderCreateActivityForm()}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  return {
    response: state.responses.get('addToCollection'),
    loadItemIsPending: selectResponseIsPending(state, 'loadItem'),
    formStage: state.ui.getIn(['formStages', CREATE_ACTIVITY_FORM], 0),
    productOrganisations: state.collections.getIn(
      ['productOrganisations', 'items'],
      null
    ),
    reportEmailDefaults: selectReportEmailDefaults(state),
    groupId: getQueryValue(props, 'group'),
    defaultProductOrganisations: getMultipleQueryValue(
      props,
      'product_organisation'
    ),
    group: selectSelectedGroup(state),
    selectedOrganisation: state.ui.getIn([
      'simpleComponentState',
      'CreateActivityPage',
      'selectedOrganisation',
    ]),
  };
}

export default connect(mapStateToProps, {
  push,
  addActivityAndStartEditing,
  setFormStage,
  resetFormStage,
  getReportEmailDefaults,
  clearCollection,
  loadGroup,
  setSimpleComponentState,
  clearSimpleComponentState,
  resetSimpleOrganisation,
  getSimpleOrganisation,
})(CreateActivityPage);
