import { Map } from 'immutable';

import makeRsaAction from '^/middleware/makeRsaAction';
import makeAsyncActionSet from '^/actions/makeAsyncActionSet';
import {
  POST,
  GET,
  PUT,
  OPTIONS,
  PATCH,
  DELETE,
} from '^/constants/httpMethods';
import { PulseFrequency } from '^/reducers/api/types';
import { DEFAULT_PULSE_TIME } from '^/components/pulses/constants';
import { API_PREFIX } from '../settings';
import { toQueryString } from '../utils';

export const PAGE_SIZE_OPTIONS = [20, 250];

export const LOGIN = makeAsyncActionSet('LOGIN');

export function login(email, password, activity) {
  return makeRsaAction(LOGIN, '/accounts/login/', POST, {
    email,
    password,
    activity,
  });
}

export const EXTERNAL_LOGIN = makeAsyncActionSet('EXTERNAL_LOGIN');

export function externalLogin(userId) {
  return makeRsaAction(EXTERNAL_LOGIN, '/accounts/login/external/', POST, {
    id: userId,
  });
}

export const SUDO_LOGIN = makeAsyncActionSet('SUDO_LOGIN');

export function sudoLogin(email, password) {
  return makeRsaAction(SUDO_LOGIN, '/accounts/login/sudo/', POST, {
    sudo_email: email,
    password,
  });
}

export const CLEAR_TOKEN = 'CLEAR_TOKEN';

export function clearToken() {
  return { type: CLEAR_TOKEN };
}

export const GET_USER_INFO = makeAsyncActionSet('GET_USER_INFO');

export function getUserInfo() {
  return makeRsaAction(GET_USER_INFO, '/accounts/me/', GET);
}

export const LOGOUT = 'LOGOUT';

export function logout(response) {
  return { type: LOGOUT, response };
}
export function logoutSkippingRedirect(response) {
  return { type: LOGOUT, response, skipRedirect: true };
}

export const SUDO_LOGOUT = 'SUDO_LOGOUT';

export function sudoLogout() {
  return { type: SUDO_LOGOUT };
}

export const SEND_RECOVERY_LINK = makeAsyncActionSet('SEND_RECOVERY_LINK');

export function sendRecoveryLink(email, next_path) {
  return makeRsaAction(SEND_RECOVERY_LINK, '/accounts/reset-password/', POST, {
    email,
    next_path,
  });
}

export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
export const CLOSE_FILTERS = 'CLOSE_FILTERS';

export function toggleFilters() {
  return { type: TOGGLE_FILTERS };
}

export function closeFilters() {
  return { type: CLOSE_FILTERS };
}

export const RESET_PASSWORD = makeAsyncActionSet('RESET_PASSWORD');

export function resetPassword(uid, token, password, password2) {
  return makeRsaAction(
    RESET_PASSWORD,
    '/accounts/reset-password/confirm/',
    POST,
    { uid, token, password, password2 }
  );
}

export const RESET_RESPONSE = 'RESET_RESPONSE';

export function resetResponse(key) {
  return { type: RESET_RESPONSE, payload: key };
}

export const INVITE_USER = makeAsyncActionSet('INVITE_USER');

export function inviteUser(id, email, opening, closing, langCode) {
  return makeRsaAction(
    INVITE_USER,
    '/invites/',
    POST,
    Object.assign(
      { user: id, email },
      { lang_code: langCode },
      opening && { opening_message: opening },
      closing && { closing_message: closing }
    )
  );
}

export const ADD_RATER_USER = makeAsyncActionSet('ADD_RATER_USER');

export function addRaterUser(
  email,
  full_name,
  external = false,
  source = false,
  external_profile = false,
  organisation
) {
  const data = Object.assign(
    { email, external, external_profile },
    full_name && { full_name },
    organisation && { organisation }
  );

  return makeRsaAction(
    ADD_RATER_USER,
    '/accounts/users/add-rater/',
    POST,
    data,
    { source }
  );
}

export const STORE_IMAGE_STYLE = 'STORE_IMAGE_STYLE';
export function storeImageStyle(ident, style) {
  return {
    type: STORE_IMAGE_STYLE,
    payload: Map({
      ident,
      style,
    }),
  };
}

export const AUTHENTICATE_TOKEN = makeAsyncActionSet('AUTHENTICATE_TOKEN');
export function authenticateInvite(inviteId) {
  return makeRsaAction(AUTHENTICATE_TOKEN, `/invites/${inviteId}/redeem/`, GET);
}

export const REDEEM_INVITE = makeAsyncActionSet('REDEEM_INVITE');
export function redeemInvite(
  inviteId,
  email,
  fullName,
  password,
  password2,
  lang_code
) {
  return makeRsaAction(REDEEM_INVITE, `/invites/${inviteId}/redeem/`, POST, {
    email,
    full_name: fullName,
    password,
    password2: password2,
    lang_code,
  });
}

export const GET_INVITE_DEFAULTS = makeAsyncActionSet('GET_INVITE_DEFAULTS');
export function getInviteDefaults(organisation) {
  return makeRsaAction(
    GET_INVITE_DEFAULTS,
    '/invites/defaults/',
    GET,
    organisation ? { organisation } : null
  );
}

export const UPDATE_PROFILE = makeAsyncActionSet('UPDATE_PROFILE');
export function updateProfile(userId, profileData) {
  return makeRsaAction(
    UPDATE_PROFILE,
    `/accounts/users/${userId}/complete/`,
    PATCH,
    profileData
  );
}

export const SET_LINE_MANAGER = makeAsyncActionSet('SET_LINE_MANAGER');
export function setLineManager(activityId, lineManagerId) {
  return makeRsaAction(
    SET_LINE_MANAGER,
    `/activities/${activityId}/set-line-manager/`,
    POST,
    { line_manager: lineManagerId }
  );
}

export const SHOW_SAVED_TOAST = 'SHOW_SAVED_TOAST';
export function showSavedToast() {
  return {
    type: SHOW_SAVED_TOAST,
  };
}

export const OPEN_MENU = 'OPEN_MENU';

export function openMenu(title) {
  return {
    type: OPEN_MENU,
    payload: title,
  };
}

export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';

export function toggleMobileMenu() {
  return {
    type: TOGGLE_MOBILE_MENU,
  };
}

export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU';

export function closeMobileMenu() {
  return {
    type: CLOSE_MOBILE_MENU,
  };
}

export const CLOSE_MENU = 'CLOSE_MENU';

export function closeMenu(title) {
  return {
    type: CLOSE_MENU,
    payload: title,
  };
}

export const GET_PRODUCT_VERSION = makeAsyncActionSet('GET_SCHEMA');
export function getProductVersion(productVersionId) {
  return makeRsaAction(
    GET_PRODUCT_VERSION,
    `/product-versions/${productVersionId}/`,
    GET
  );
}

export const GET_SCHEMA = makeAsyncActionSet('GET_SCHEMA');
export function getSchema(endpoint, objectId) {
  return makeRsaAction(GET_SCHEMA, `/${endpoint}/${objectId}/`, OPTIONS);
}

export const SET_PRODUCT_VERSIONS_SEARCH_STRING =
  'SET_PRODUCT_VERSIONS_SEARCH_STRING';

export function setProductVersionsSearchString(searchString) {
  return { type: SET_PRODUCT_VERSIONS_SEARCH_STRING, payload: searchString };
}

export const ADD_USERS_TO_ACTIVITY = makeAsyncActionSet(
  'ADD_USERS_TO_ACTIVITY'
);

export function addUsersToActivity(activityId, userIds) {
  return makeRsaAction(
    ADD_USERS_TO_ACTIVITY,
    `/activities/${activityId}/add-users/`,
    POST,
    { users: userIds }
  );
}

export const ADD_USERS_TO_GROUP = makeAsyncActionSet('ADD_USERS_TO_GROUP');

export function addUsersToGroup(groupId, userIds) {
  return makeRsaAction(
    ADD_USERS_TO_GROUP,
    `/organisations/groups/${groupId}/add-user/`,
    POST,
    { users: userIds }
  );
}

export const ADD_REMOVE_USERS_GROUP = makeAsyncActionSet(
  'ADD_REMOVE_USERS_GROUP'
);
export const addRemoveUsersGroup = (groupId, userIds) => {
  return makeRsaAction(
    ADD_REMOVE_USERS_GROUP,
    `/organisations/groups/${groupId}/add-remove-users/`,
    POST,
    { users: Array.from(userIds) }
  );
};

export const REMOVE_USER_FROM_ACTIVITY = makeAsyncActionSet(
  'REMOVE_USER_FROM_ACTIVITY'
);

export function removeUserFromActivity(activityId, userId) {
  return makeRsaAction(
    REMOVE_USER_FROM_ACTIVITY,
    `/activities/${activityId}/remove-users/`,
    POST,
    { users: [userId] }
  );
}

export function removeUsersFromActivity(activityId, userIds) {
  return makeRsaAction(
    REMOVE_USER_FROM_ACTIVITY,
    `/activities/${activityId}/remove-users/`,
    POST,
    { users: userIds }
  );
}

export const ADD_GROUPS_TO_ACTIVITY = makeAsyncActionSet(
  'ADD_GROUPS_TO_ACTIVITY'
);

export function addGroupsToActivity(activityId, groupIds) {
  return makeRsaAction(
    ADD_GROUPS_TO_ACTIVITY,
    `/activities/${activityId}/add-groups/`,
    POST,
    { groups: groupIds }
  );
}

export const ADD_PRODUCT_TO_ACCOUNT = makeAsyncActionSet(
  'ADD_PRODUCT_TO_ACCOUNT'
);

export function addProductToAccount(organisationId, productId, isSelected) {
  return makeRsaAction(
    ADD_PRODUCT_TO_ACCOUNT,
    `/organisations/${organisationId}/product-types/`,
    POST,
    { product: productId, is_selected: isSelected },
    { source: productId, organisationId, productId, isSelected }
  );
}

export const ADD_ALL_PRODUCTS_TO_ACCOUNT = makeAsyncActionSet(
  'ADD_ALL_PRODUCTS_TO_ACCOUNT'
);

export function addAllProductsToAccount(organisationId) {
  return makeRsaAction(
    ADD_ALL_PRODUCTS_TO_ACCOUNT,
    `/organisations/${organisationId}/all-product-types/`,
    POST
  );
}

export const ADD_ADMIN_TO_ORGANISATION = makeAsyncActionSet(
  'ADD_ADMIN_TO_ORGANISATION'
);

export function addAdminToOrganisation(organisationId, email, full_name, role) {
  return makeRsaAction(
    ADD_ADMIN_TO_ORGANISATION,
    `/organisations/${organisationId}/admins/add/`,
    POST,
    { email, full_name, role }
  );
}

export const REMOVE_ADMIN_FROM_ORGANISATION = makeAsyncActionSet(
  'REMOVE_ADMIN_FROM_ORGANISATION'
);

export function removeAdminFromOrganisation(organisationId, userId) {
  return makeRsaAction(
    REMOVE_ADMIN_FROM_ORGANISATION,
    `/organisations/${organisationId}/admins/remove/`,
    POST,
    { user: userId },
    { source: userId }
  );
}

export const ADD_REPORT_TO_PRODUCT_ORGANISATION = makeAsyncActionSet(
  'ADD_REPORT_TO_PRODUCT_ORGANISATION'
);
export function addReportToProductOrganisation(
  productOrganisationId,
  reportId
) {
  return makeRsaAction(
    ADD_REPORT_TO_PRODUCT_ORGANISATION,
    `/organisations/product-organisations/${productOrganisationId}/add-report-template/`,
    POST,
    { report_template: reportId },
    { source: reportId }
  );
}

export const REMOVE_REPORT_FROM_PRODUCT_ORGANISATION = makeAsyncActionSet(
  'REMOVE_REPORT_FROM_PRODUCT_ORGANISATION'
);
export function removeReportFromProductOrganisation(
  productOrganisationId,
  reportId
) {
  return makeRsaAction(
    REMOVE_REPORT_FROM_PRODUCT_ORGANISATION,
    `/organisations/product-organisations/${productOrganisationId}/remove-report-template/`,
    POST,
    { report_template: reportId },
    { source: reportId }
  );
}

export const EDIT_FIELD = 'EDIT_FIELD';
export const STOP_EDITING_FIELD = 'STOP_EDITING_FIELD';
export const TOGGLE_EDITING_FIELD = 'TOGGLE_EDITING_FIELD';

export function editField(id) {
  return { type: EDIT_FIELD, payload: { id } };
}

export function stopEditingField(id) {
  return { type: STOP_EDITING_FIELD, payload: { id } };
}

export function toggleEditingField(id) {
  return { type: TOGGLE_EDITING_FIELD, payload: { id } };
}

export const EXPAND_UI_COMPONENT = 'EXPAND_UI_COMPONENT';
export const COLLAPSE_UI_COMPONENT = 'COLLAPSE_UI_COMPONENT';

export function expandUiComponent(id, groupId) {
  return { type: EXPAND_UI_COMPONENT, payload: { id, groupId } };
}

export function collapseUiComponent(id, groupId) {
  return { type: COLLAPSE_UI_COMPONENT, payload: { id, groupId } };
}

export const COLLAPSE_ALL_UI_COMPONENTS_IN_GROUP =
  'COLLAPSE_ALL_UI_COMPONENTS_IN_GROUP';
export function collapseAllUiComponentsInGroup(groupId) {
  return { type: COLLAPSE_ALL_UI_COMPONENTS_IN_GROUP, payload: { groupId } };
}

export const TOGGLE_PANE = 'TOGGLE_PANE';
export const CLOSE_PANESET = 'CLOSE_PANESET';

export function togglePane(paneset, pane) {
  return { type: TOGGLE_PANE, payload: { paneset, pane } };
}

export function closePaneset(paneset) {
  return { type: CLOSE_PANESET, payload: { paneset } };
}

export const TOGGLE_SELECTION = 'TOGGLE_SELECTION';

export function toggleSelection(namespace, id) {
  return { type: TOGGLE_SELECTION, payload: { namespace, id } };
}

export const SET_SELECTION = 'SET_SELECTION';

export function setSelection(namespace, id, selected) {
  return { type: SET_SELECTION, payload: { namespace, id, selected } };
}

export const INITIALISE_SELECTION = 'INITIALISE_SELECTION';

export function initialiseSelection(namespace, ids) {
  return { type: INITIALISE_SELECTION, payload: { namespace, ids } };
}

export const SORT_SELECTION = 'SORT_SELECTION';

export function sortSelection(namespace, value) {
  return {
    type: SORT_SELECTION,
    payload: { namespace, value },
    meta: { collectionName: namespace },
  };
}

export const CLEAR_SELECTION = 'CLEAR_SELECTION';

export function clearSelection(namespace) {
  return { type: CLEAR_SELECTION, payload: { namespace } };
}

function makeAnswerUrl(activityId, productVersionId, raterForId) {
  return (
    `/activities/${activityId}/product-version/${productVersionId}/` +
    (raterForId ? `rater-for/${raterForId}/` : '') +
    'user-answers/'
  );
}

export const GET_MY_ANSWERS = makeAsyncActionSet('GET_MY_ANSWERS');
export function getMyAnswers(activityId, productVersionId, raterForId) {
  return makeRsaAction(
    GET_MY_ANSWERS,
    makeAnswerUrl(activityId, productVersionId, raterForId),
    GET
  );
}

export const GET_MY_IMAGE_MATCH_ANSWERS = makeAsyncActionSet(
  'GET_MY_IMAGE_MATCH_ANSWERS'
);
export function getMyImageMatchAnswers(
  activityId,
  productVersionId,
  raterForId
) {
  return makeRsaAction(
    GET_MY_IMAGE_MATCH_ANSWERS,
    makeAnswerUrl(activityId, productVersionId, raterForId) + 'map/',
    GET
  );
}

export const GET_MY_MULTI_LIKERT_ANSWERS = makeAsyncActionSet(
  'GET_MY_MULTI_LIKERT_ANSWERS'
);
export function getMyMultiLikertAnswers(
  activityId,
  productVersionId,
  raterForId
) {
  return makeRsaAction(
    GET_MY_MULTI_LIKERT_ANSWERS,
    makeAnswerUrl(activityId, productVersionId, raterForId) + 'multi-likert/',
    GET
  );
}

export const CLEAR_ANSWERS = 'CLEAR_ANSWERS';
export function clearAnswers() {
  return { type: CLEAR_ANSWERS };
}

export const ANSWER_QUESTION = makeAsyncActionSet('ANSWER_QUESTION');
export function answerQuestion(
  activityId,
  productVersionId,
  raterForId,
  questionId,
  score
) {
  return makeRsaAction(
    ANSWER_QUESTION,
    makeAnswerUrl(activityId, productVersionId, raterForId),
    POST,
    { score, question: questionId },
    { source: questionId }
  );
}
export function answerQuestionOption(
  activityId,
  productVersionId,
  questionOptionId,
  choice
) {
  return makeRsaAction(
    ANSWER_QUESTION,
    makeAnswerUrl(activityId, productVersionId),
    POST,
    { image_match_question_option: questionOptionId, choice },
    { source: questionOptionId }
  );
}

export const ANSWER_MULTIPLE_QUESTIONS = makeAsyncActionSet(
  'ANSWER_MULTIPLE_QUESTIONS'
);
export function answerMultipleQuestions(
  activityId,
  productVersionId,
  raterForId,
  answers
) {
  const url = raterForId
    ? `/activities/${activityId}/product-version/${productVersionId}/rater-for/${raterForId}/multiple-answers/`
    : `/activities/${activityId}/product-version/${productVersionId}/multiple-answers/`;
  return makeRsaAction(ANSWER_MULTIPLE_QUESTIONS, url, POST, answers.toJS());
}

export const ANSWER_MULTI_LIKERT_QUESTION = makeAsyncActionSet(
  'ANSWER_MULTIPLE_QUESTION'
);
export function answerMultiLikertQuestion(
  activityId,
  productVersionId,
  answers
) {
  const url =
    makeAnswerUrl(activityId, productVersionId, null) + 'multi-likert/';
  return makeRsaAction(ANSWER_MULTI_LIKERT_QUESTION, url, POST, {
    values: answers,
  });
}

export const GET_ORGANISATION_ALL_REPORTS = makeAsyncActionSet(
  'GET_ORGANISATION_ALL_REPORTS'
);
export function getOrganisationAllReports(organisationId) {
  return makeRsaAction(
    GET_ORGANISATION_ALL_REPORTS,
    `/reports/organisation/${organisationId}/`,
    GET,
    null,
    { organisationId }
  );
}

export const GET_ORGANISATION_ALL_REPORTS_SIMPLE = makeAsyncActionSet(
  'GET_ORGANISATION_ALL_REPORTS_SIMPLE'
);
export function getOrganisationAllReportsSimple(organisationId) {
  return makeRsaAction(
    GET_ORGANISATION_ALL_REPORTS_SIMPLE,
    `/reports/organisation/${organisationId}/simple/`,
    GET,
    null,
    { organisationId }
  );
}

export const GET_MY_GENERATING_REPORTS = makeAsyncActionSet(
  'GET_MY_GENERATING_REPORTS'
);
export function getMyGeneratingReports(date) {
  return makeRsaAction(GET_MY_GENERATING_REPORTS, `/reports/generating/`, GET, {
    date,
  });
}

export const GET_USER_REPORTS = makeAsyncActionSet('GET_USER_REPORTS');
export function getUserReports(user, filters) {
  return makeRsaAction(
    GET_USER_REPORTS,
    `/reports/grouped-by-activity/user/${user}/`,
    GET,
    filters
  );
}

export const GET_USER_PULSES = makeAsyncActionSet('GET_USER_PULSES');
export function getUserPulses(userId, filters) {
  return makeRsaAction(
    GET_USER_PULSES,
    `/reports/grouped-by-pulses/user/${userId}/`,
    GET,
    filters
  );
}

export const FIX_HEADER = 'FIX_HEADER';
export const UNFIX_HEADER = 'UNFIX_HEADER';

export function fixHeader(width) {
  return {
    type: FIX_HEADER,
    payload: width,
  };
}

export function unfixHeader() {
  return {
    type: UNFIX_HEADER,
  };
}

export const SET_PRODUCT_VERSION_PAGE = 'SET_PRODUCT_VERSION_PAGE';
export function setProductVersionPage(page) {
  return { type: SET_PRODUCT_VERSION_PAGE, payload: { page } };
}

export const SHOW_NEXT_PRODUCT_VERSION_PAGE = 'SHOW_NEXT_PRODUCT_VERSION_PAGE';
export function showNextProductVersionPage() {
  return { type: SHOW_NEXT_PRODUCT_VERSION_PAGE };
}

export const STORE_PRODUCT_VERSION_ANSWER = 'STORE_PRODUCT_VERSION_ANSWER';
export function storeProductVersionAnswer(questionId, answer) {
  return {
    type: STORE_PRODUCT_VERSION_ANSWER,
    payload: { questionId, answer },
  };
}

export const SAVE_ISP_ANSWERS = makeAsyncActionSet('SAVE_ISP_ANSWERS');
export function saveISPAnswers(
  activityId,
  productVersionId,
  raterForId,
  questionGroup,
  answers
) {
  const url = raterForId
    ? `/activities/${activityId}/product-version/${productVersionId}/rater-for/${raterForId}/multiple-answers/`
    : `/activities/${activityId}/product-version/${productVersionId}/multiple-answers/`;
  return makeRsaAction(
    SAVE_ISP_ANSWERS,
    url,
    POST,
    answers
      .reverse()
      .map((question, index) =>
        Map({ grouped_question: question, score: index + 1 })
      )
      .toJS()
  );
}

export const SAVE_RANKING_ANSWERS = makeAsyncActionSet('SAVE_RANKING_ANSWERS');
export function saveRankingAnswers(
  activityId,
  productVersionId,
  questionGroup,
  answers
) {
  return makeRsaAction(
    SAVE_RANKING_ANSWERS,
    `/activities/${activityId}/product-version/${productVersionId}/multiple-answers/`,
    POST,
    answers
      .reverse()
      .map((question, index) =>
        Map({ competencies_grouped_question: question, score: index + 1 })
      )
      .toJS()
  );
}

export const SAVE_SJT_ANSWERS = makeAsyncActionSet('SAVE_SJT_ANSWERS');
export function saveSJTAnswers(
  activityId,
  productVersionId,
  questionGroup,
  answers
) {
  const url = `/activities/${activityId}/product-version/${productVersionId}/multiple-answers/`;
  return makeRsaAction(
    SAVE_SJT_ANSWERS,
    url,
    POST,
    answers
      .map((option, index) =>
        Map({ ordered_question_option: option, score: index + 1 })
      )
      .toJS()
  );
}

export const SAVE_PERSPECTIVES_LIKERT = makeAsyncActionSet(
  'SAVE_PERSPECTIVES_LIKERT'
);

export function savePerspectivesLikert(sessionId) {
  return makeRsaAction(
    SAVE_PERSPECTIVES_LIKERT,
    `/activities/perspectives/${sessionId}/competencies/create-grouped-questions/`,
    GET
  );
}

export const SCROLL_TOP = 'SCROLL_TOP';
export function scrollTop(element) {
  return { type: SCROLL_TOP, payload: element };
}

export const ON_BEFORE_UNLOAD = 'ON_BEFORE_UNLOAD';
export function onBeforeUnload(message) {
  return { type: ON_BEFORE_UNLOAD, payload: message };
}

export const OFF_BEFORE_UNLOAD = 'OFF_BEFORE_UNLOAD';
export function offBeforeUnload() {
  return { type: OFF_BEFORE_UNLOAD };
}

export const START_ACTIVITY_PRODUCT_VERSION_SESSION = makeAsyncActionSet(
  'START_ACTIVITY_PRODUCT_VERSION_SESSION'
);
export function startActivityProductVersionSession(
  activityId,
  productVersionId,
  raterForId
) {
  const url = raterForId
    ? `/activities/${activityId}/product-version/${productVersionId}/rater-for/${raterForId}/start-session/`
    : `/activities/${activityId}/product-version/${productVersionId}/start-session/`;

  return makeRsaAction(START_ACTIVITY_PRODUCT_VERSION_SESSION, url, POST, {});
}

export const UPDATE_ACTIVITY_PRODUCT_VERSION_SESSION = makeAsyncActionSet(
  'UPDATE_ACTIVITY_PRODUCT_VERSION_SESSION'
);
export function updateActivityProductVersionSession(
  activityId,
  productVersionId,
  raterForId,
  data
) {
  const url = raterForId
    ? `/activities/${activityId}/product-version/${productVersionId}/rater-for/${raterForId}/update-session/`
    : `/activities/${activityId}/product-version/${productVersionId}/update-session/`;

  return makeRsaAction(UPDATE_ACTIVITY_PRODUCT_VERSION_SESSION, url, PUT, data);
}

export const GET_COMPETENCIES_GROUPED_QUESTIONS = makeAsyncActionSet(
  'GET_COMPETENCIES_GROUPED_QUESTIONS'
);
export function rankedQuestions(sessionId) {
  return makeRsaAction(
    GET_COMPETENCIES_GROUPED_QUESTIONS,
    `/activities/perspectives/${sessionId}/competencies/get-grouped-questions/`,
    GET
  );
}

export const CREATE_ACTIVITY_PRODUCT_VERSION_SESSION_EVENT = makeAsyncActionSet(
  'CREATE_ACTIVITY_PRODUCT_VERSION_SESSION_EVENT'
);
export function createActivityProductVersionSessionEvent(
  activityId,
  productVersionId,
  questionId,
  raterForId
) {
  const url = raterForId
    ? `/activities/${activityId}/product-version/${productVersionId}/rater-for/${raterForId}/create-event/`
    : `/activities/${activityId}/product-version/${productVersionId}/create-event/`;

  return makeRsaAction(
    CREATE_ACTIVITY_PRODUCT_VERSION_SESSION_EVENT,
    url,
    POST,
    { question: questionId }
  );
}

export const IMPORT_USERS = makeAsyncActionSet('IMPORT_USERS');

export function importUsers(organisation, users, groupId) {
  return makeRsaAction(IMPORT_USERS, `/accounts/users/import/`, POST, {
    organisation,
    users,
    groupId,
  });
}

export const POLL_PROGRESS = makeAsyncActionSet('POLL_PROGRESS');

export function pollProgress(importId) {
  return makeRsaAction(
    POLL_PROGRESS,
    `/accounts/users/import/${importId}/progress/`,
    GET
  );
}

export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export function updateProgress(key, progress, importId) {
  return { type: UPDATE_PROGRESS, payload: { key, progress, importId } };
}

export const SORT_LIST = 'SORT_LIST';
export function sortList(list, sortBy) {
  return { type: SORT_LIST, payload: { list, sortBy } };
}

export const SET_CONTROL_BAR_OPACITY = 'SET_CONTROL_BAR_OPACITY';
export function setControlBarOpaque(opaque) {
  return {
    type: SET_CONTROL_BAR_OPACITY,
    payload: opaque,
  };
}

export const SET_EXPORT_PRODUCT = 'SET_EXPORT_PRODUCT';
export function setExportProduct(productId) {
  return {
    type: SET_EXPORT_PRODUCT,
    payload: productId,
  };
}

export const SET_EXPORT_ACTIVITY = 'SET_EXPORT_ACTIVITY';
export function setExportActivity(activityId) {
  return {
    type: SET_EXPORT_ACTIVITY,
    payload: activityId,
  };
}

export const SET_EXPORT_ORGANISATION = 'SET_EXPORT_ORGANISATION';
export function setExportOrganisation(organisationId) {
  return {
    type: SET_EXPORT_ORGANISATION,
    payload: organisationId,
  };
}

export const SET_EXPORT_USER = 'SET_EXPORT_USER';
export function setExportUser(userId) {
  return {
    type: SET_EXPORT_USER,
    payload: userId,
  };
}

export const SET_EXPORT_TEAM = 'SET_EXPORT_TEAM';
export function setExportTeam(teamId) {
  return {
    type: SET_EXPORT_TEAM,
    payload: teamId,
  };
}

export const VIEW_EXPORT = 'VIEW_EXPORT';
export function viewExport(token, exportType, metadata) {
  return { type: VIEW_EXPORT, payload: { token, exportType, metadata } };
}

export const RESET_EXPORT_SELECTIONS = 'RESET_EXPORT_SELECTIONS';
export function resetExportSelections() {
  return { type: RESET_EXPORT_SELECTIONS };
}

export const CREATE_REPORT_DOWNLOAD = makeAsyncActionSet(
  'CREATE_REPORT_DOWNLOAD'
);
export function createReportDownload(reports) {
  return makeRsaAction(
    CREATE_REPORT_DOWNLOAD,
    `/reports/create-download/`,
    POST,
    { reports }
  );
}

export const CREATE_REPORT_NOTIFICATION_EMAIL = makeAsyncActionSet(
  'CREATE_REPORT_NOTIFICATION_EMAIL'
);
export function createReportNotificationEmail(activityId, data) {
  return makeRsaAction(
    CREATE_REPORT_NOTIFICATION_EMAIL,
    `/activities/${activityId}/report-notification-emails/`,
    POST,
    data,
    {
      activityId,
    }
  );
}

export const UPDATE_REPORT_NOTIFICATION_EMAIL = makeAsyncActionSet(
  'UPDATE_REPORT_NOTIFICATION_EMAIL'
);
export function updateReportNotificationEmail(
  activityId,
  notificationId,
  data
) {
  return makeRsaAction(
    UPDATE_REPORT_NOTIFICATION_EMAIL,
    `/activities/${activityId}/report-notification-emails/${notificationId}/`,
    PUT,
    data
  );
}

export const CREATE_NOTIFICATION_EMAIL = makeAsyncActionSet(
  'CREATE_NOTIFICATION_EMAIL'
);
export function createNotificationEmail(activityId, data) {
  return makeRsaAction(
    CREATE_NOTIFICATION_EMAIL,
    `/activities/${activityId}/notification-emails/`,
    POST,
    data,
    {
      activityId,
    }
  );
}

export const UPDATE_NOTIFICATION_EMAIL = makeAsyncActionSet(
  'UPDATE_NOTIFICATION_EMAIL'
);
export function updateNotificationEmail(activityId, notificationId, data) {
  return makeRsaAction(
    UPDATE_NOTIFICATION_EMAIL,
    `/activities/${activityId}/notification-emails/${notificationId}/`,
    PUT,
    data
  );
}

export const DELETE_NOTIFICATION_EMAIL = makeAsyncActionSet(
  'DELETE_NOTIFICATION_EMAIL'
);
export function deleteNotificationEmail(activityId, notificationId) {
  return makeRsaAction(
    DELETE_NOTIFICATION_EMAIL,
    `/activities/${activityId}/notification-emails/${notificationId}/`,
    'DELETE'
  );
}

export const EXPAND_FILTER = 'EXPAND_FILTER';

export function expandFilter(id) {
  return { type: EXPAND_FILTER, payload: { id } };
}

export const RESEND_NOTIFICATION_EMAIL = makeAsyncActionSet(
  'RESEND_NOTIFICATION_EMAIL'
);
export function resendNotificationEmail(activityId, type) {
  return makeRsaAction(
    RESEND_NOTIFICATION_EMAIL,
    `/activities/${activityId}/notification-emails/resend/`,
    POST,
    { type }
  );
}

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export function toggleSidebar() {
  return { type: TOGGLE_SIDEBAR };
}

export const VIEW_AVERAGES_REPORT = makeAsyncActionSet('VIEW_AVERAGES_REPORT');

export function viewAveragesReport(data) {
  return makeRsaAction(
    VIEW_AVERAGES_REPORT,
    '/exports/organisation-averages/get/',
    GET,
    data
  );
}

export const SET_FILTER = 'SET_FILTER';

export function setFilter(key, value) {
  return { type: SET_FILTER, payload: { key, value } };
}

export const SET_FILTERS = 'SET_FILTERS';

export function setFilters(filterSpec) {
  return { type: SET_FILTERS, payload: { filterSpec } };
}

export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export function clearFilters() {
  return { type: CLEAR_FILTERS };
}

export const SET_WELCOME_MODAL_PAGE = 'SET_WELCOME_MODAL_PAGE';

export function setWelcomeModalPage(page) {
  return { type: SET_WELCOME_MODAL_PAGE, payload: page };
}

export const SET_ADDITIONAL_WELCOME_MODAL_PAGE =
  'SET_ADDITIONAL_WELCOME_MODAL_PAGE';

export function setAdditionalWelcomeModalPage(page) {
  return { type: SET_ADDITIONAL_WELCOME_MODAL_PAGE, payload: page };
}

export const RESET_ADDITIONAL_WELCOME_MODAL_PAGE =
  'RESET_ADDITIONAL_WELCOME_MODAL_PAGE';

export function resetAdditionalWelcomeModalPage() {
  return { type: RESET_ADDITIONAL_WELCOME_MODAL_PAGE };
}

export const SET_MAP_PRODUCT_VERSION_QUESTION_INDEX =
  'SET_MAP_PRODUCT_VERSION_QUESTION_INDEX';

export function setMAPProductVersionQuestionIndex(index) {
  return { type: SET_MAP_PRODUCT_VERSION_QUESTION_INDEX, payload: index };
}

export const SET_IS_REORDERING = 'SET_IS_REORDERING';

export function setIsReordering(isReordering) {
  return { type: SET_IS_REORDERING, payload: isReordering };
}

export const GET_STATS = makeAsyncActionSet('GET_STATS');

export function getStats() {
  return makeRsaAction(GET_STATS, '/activities/stats/', GET);
}

export const SIGN_UP_USER = makeAsyncActionSet('SIGN_UP_USER');
export function signUpUser(
  email,
  fullName,
  password,
  password2,
  activity,
  lang_code
) {
  return makeRsaAction(SIGN_UP_USER, `/accounts/signup/`, POST, {
    email,
    full_name: fullName,
    password,
    password2,
    activity,
    lang_code,
  });
}

export const ACCOUNT_SIGN_UP = makeAsyncActionSet('ACCOUNT_SIGN_UP');
export function accountSignUp(data) {
  return makeRsaAction(ACCOUNT_SIGN_UP, `/organisations/signup/`, POST, data);
}

export const SIGN_UP_ANONYMOUS_USER = makeAsyncActionSet(
  'SIGN_UP_ANONYMOUS_USER'
);
export function signUpAnonymousUser(date_of_birth, activity) {
  return makeRsaAction(
    SIGN_UP_ANONYMOUS_USER,
    `/accounts/anonymous-signup/`,
    POST,
    { date_of_birth, activity }
  );
}

export const VERIFY_VALIDATION_CODE = makeAsyncActionSet(
  'VERIFY_VALIDATION_CODE'
);
export function verifyValidationCode(validationCode) {
  return makeRsaAction(
    VERIFY_VALIDATION_CODE,
    `/accounts/verify/${validationCode}/`,
    POST
  );
}

export const SET_PURCHASE_HISTORY_FIELD = 'SET_PURCHASE_HISTORY_FIELD';

export function setPurchaseHistoryField(key, value) {
  return { type: SET_PURCHASE_HISTORY_FIELD, payload: { key, value } };
}

export const ADD_NOMINATION_RULE = makeAsyncActionSet('ADD_NOMINATION_RULE');

export function addNominationRule(activityId, data) {
  return makeRsaAction(
    ADD_NOMINATION_RULE,
    `/activities/${activityId}/nomination-rules/`,
    POST,
    data,
    { activityId }
  );
}

export const DELETE_NOMINATION_RULE = makeAsyncActionSet(
  'DELETE_NOMINATION_RULE'
);
export function deleteNominationRule(activityId, ruleId) {
  return makeRsaAction(
    DELETE_NOMINATION_RULE,
    `/activities/${activityId}/nomination-rules/${ruleId}/`,
    'DELETE',
    null,
    { activityId, ruleId }
  );
}

export const USER_REPORTS_ADD_FILTER = 'USER_REPORTS_ADD_FILTER';
export function userReportsAddFilter(payload) {
  return { type: USER_REPORTS_ADD_FILTER, payload };
}

export const USER_REPORTS_CLEAR_FILTER = 'USER_REPORTS_CLEAR_FILTER';
export function userReportsClearFilter(payload) {
  return { type: USER_REPORTS_CLEAR_FILTER, payload };
}

export const USER_REPORTS_SORT_BY = 'USER_REPORTS_SORT_BY';
export function userReportsSortBy(payload) {
  return { type: USER_REPORTS_SORT_BY, payload };
}

export const USER_REPORTS_REVERSE_SORT = 'USER_REPORTS_REVERSE_SORT';
export function userReportsReverseSort() {
  return { type: USER_REPORTS_REVERSE_SORT };
}
export const USER_GROUPS_SORT_BY = 'USER_GROUPS_SORT_BY';
export function userGroupsSortBy(payload) {
  return { type: USER_GROUPS_SORT_BY, payload };
}

export const USER_GROUPS_REVERSE_SORT = 'USER_GROUPS_REVERSE_SORT';
export function userGroupsReverseSort() {
  return { type: USER_GROUPS_REVERSE_SORT };
}

export const VIEW_ADMIN_DASHBOARD = 'VIEW_ADMIN_DASHBOARD';
export function viewAdminDashboard() {
  return { type: VIEW_ADMIN_DASHBOARD };
}

export const GET_TOKEN = makeAsyncActionSet('GET_TOKEN');

export function getToken() {
  return makeRsaAction(GET_TOKEN, '/accounts/token/get/', GET);
}

export const ADD_RATER_TO_ACTIVITY = makeAsyncActionSet(
  'ADD_RATER_TO_ACTIVITY'
);
export function addRaterToActivity(activityId, roleId, userId, source) {
  return makeRsaAction(
    ADD_RATER_TO_ACTIVITY,
    `/activities/${activityId}/add-raters/${roleId}/`,
    POST,
    { users: [userId] },
    { source }
  );
}

export const REMOVE_LINE_MANAGER_FROM_ACTIVITY = makeAsyncActionSet(
  'REMOVE_LINE_MANAGER_FROM_ACTIVITY'
);
export function removeLineManagerFromActivity(activityId, userId, source) {
  let url = `/activities/${activityId}/remove-line-manager/`;

  return makeRsaAction(
    REMOVE_LINE_MANAGER_FROM_ACTIVITY,
    url,
    POST,
    { users: [userId] },
    { source }
  );
}

export const REMOVE_RATER_FROM_ACTIVITY = makeAsyncActionSet(
  'REMOVE_RATER_FROM_ACTIVITY'
);
export function removeRaterFromActivity(activityId, roleId, userId, source) {
  let url = `/activities/${activityId}/remove-raters/${roleId}/`;

  return makeRsaAction(
    REMOVE_RATER_FROM_ACTIVITY,
    url,
    POST,
    { users: [userId] },
    { source }
  );
}

export const UPDATE_RATER = makeAsyncActionSet('UPDATE_RATER');

export function updateRater(activityId, userId, raterId, data) {
  return makeRsaAction(
    UPDATE_RATER,
    `/activities/${activityId}/update-rater/${userId}/${raterId}/`,
    PUT,
    data,
    { activityId, userId, raterId }
  );
}

export const ADD_REPORT_RECIPIENT = makeAsyncActionSet('ADD_REPORT_RECIPIENT');
export function addReportRecipient(
  activityId,
  userId,
  reportRecipientId,
  sourceId
) {
  return makeRsaAction(
    ADD_REPORT_RECIPIENT,
    `/activities/${activityId}/add-report-recipient/${userId}/`,
    POST,
    { id: reportRecipientId },
    { source: sourceId }
  );
}

export const REMOVE_REPORT_RECIPIENT = makeAsyncActionSet(
  'REMOVE_REPORT_RECIPIENT'
);
export function removeReportRecipient(activityId, userId, reportRecipientId) {
  return makeRsaAction(
    REMOVE_REPORT_RECIPIENT,
    `/activities/${activityId}/remove-report-recipient/${userId}/`,
    POST,
    { id: reportRecipientId }
  );
}

export const UPDATE_ACTIVITY_USER = makeAsyncActionSet('UPDATE_ACTIVITY_USER');
export function updateActivityUser(activityId, userId, userData) {
  return makeRsaAction(
    UPDATE_ACTIVITY_USER,
    `/activities/${activityId}/users/${userId}/`,
    PUT,
    userData
  );
}

export const UPDATE_SELECT_USER_QUERY = 'UPDATE_SELECT_USER_QUERY';
export function updateSelectUserQuery(key, value) {
  return { type: UPDATE_SELECT_USER_QUERY, payload: { key, value } };
}

export const SET_SEARCH = 'SET_SEARCH';
export function setSearch(searchString) {
  return { type: SET_SEARCH, payload: searchString };
}

export const UPDATE_ACTIVITY_REPORT_VISIBILITY = makeAsyncActionSet(
  'UPDATE_ACTIVITY_REPORT_VISIBILITY'
);
export function updateActivityReportVisibility(activityId, visibilityData) {
  return makeRsaAction(
    UPDATE_ACTIVITY_REPORT_VISIBILITY,
    `/activities/${activityId}/report-visibility/`,
    PUT,
    visibilityData,
    { activityId }
  );
}

export const SUBMIT_RATERS = makeAsyncActionSet('SUBMIT_RATERS');
export function submitRaters(activityId) {
  return makeRsaAction(
    SUBMIT_RATERS,
    `/activities/${activityId}/submit-raters/`,
    POST
  );
}

export const SET_HIGHLIGHTED_INDEX = 'SET_HIGHLIGHTED_INDEX';

export function setHighlightedIndex(id, index) {
  return { type: SET_HIGHLIGHTED_INDEX, payload: { id, index } };
}

export const CLEAR_HIGHLIGHTED_INDEX = 'CLEAR_HIGHLIGHTED_INDEX';

export function clearHighlightedIndex(id) {
  return { type: CLEAR_HIGHLIGHTED_INDEX, payload: { id } };
}

export const MUTE_ACTIVITY_NOTIFICATION = makeAsyncActionSet(
  'MUTE_ACTIVITY_NOTIFICATION'
);
export function muteActivityNotification(activityId, type, mute) {
  return makeRsaAction(
    MUTE_ACTIVITY_NOTIFICATION,
    `/activities/${activityId}/notification-emails/mute/`,
    POST,
    { type, mute },
    { source: type }
  );
}

export const SET_MAP_PRODUCT_VERSION_PAGE = 'SET_MAP_PRODUCT_VERSION_PAGE';
export function setMapProductVersionPage(page) {
  return { type: SET_MAP_PRODUCT_VERSION_PAGE, payload: page };
}

export const START_MAP_PRODUCT_VERSION_TIMER =
  'START_MAP_PRODUCT_VERSION_TIMER';
export function startMapProductVersionTimer() {
  return { type: START_MAP_PRODUCT_VERSION_TIMER };
}

export const TIMER_START = 'TIMER_START';
export function timerStart() {
  return { type: TIMER_START };
}

export const TIMER_TICK = 'TIMER_TICK';
export function timerTick() {
  return { type: TIMER_TICK };
}

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export function setActiveTab(tabsetName, value) {
  return { type: SET_ACTIVE_TAB, payload: { tabsetName, value } };
}

export const SET_CONFIRM_BUTTON = 'SET_CONFIRM_BUTTON';
export function setConfirmButton(key, text, className, isConfirming) {
  return {
    type: SET_CONFIRM_BUTTON,
    payload: { key, text, className, isConfirming },
  };
}

export const TOGGLE_BUTTON = 'TOGGLE_BUTTON';
export function toggleButton(text, className, isToggled) {
  return { type: TOGGLE_BUTTON, payload: { text, className, isToggled } };
}

export const SET_EDITOR_MODE = 'SET_EDITOR_MODE';
export function setEditorMode(mode) {
  return { type: SET_EDITOR_MODE, payload: { mode } };
}

export const SET_CURRENT_PAGE_INDEX = 'SET_CURRENT_PAGE_INDEX';
export function setCurrentPageIndex(pageIndex) {
  return { type: SET_CURRENT_PAGE_INDEX, payload: pageIndex };
}

export const VIEW_AVERAGES_SET_ORGANISATION = 'VIEW_AVERAGES_SET_ORGANISATION';
export function viewAveragesSetOrganisation(orgId) {
  return { type: VIEW_AVERAGES_SET_ORGANISATION, payload: orgId };
}

export const SET_SIMPLE_COMPONENT_STATE = 'SET_SIMPLE_COMPONENT_STATE';
export function setSimpleComponentState(pathOrIndex, value) {
  return { type: SET_SIMPLE_COMPONENT_STATE, payload: { pathOrIndex, value } };
}

export const CLEAR_SIMPLE_COMPONENT_STATE = 'CLEAR_SIMPLE_COMPONENT_STATE';
export function clearSimpleComponentState(pathOrIndex) {
  return { type: CLEAR_SIMPLE_COMPONENT_STATE, payload: { pathOrIndex } };
}

export const SET_ACTIVITY_PRODUCT_VERSION_SESSION_CAN_RESUME =
  'SET_ACTIVITY_PRODUCT_VERSION_SESSION_CAN_RESUME';
export function setActivityProductVersionSessionCanResume(canResume) {
  return {
    type: SET_ACTIVITY_PRODUCT_VERSION_SESSION_CAN_RESUME,
    payload: { canResume },
  };
}

export const CREATE_REPORTS = makeAsyncActionSet('CREATE_REPORTS');
export function createReports(reportDetails) {
  return makeRsaAction(
    CREATE_REPORTS,
    `/reports/generate/`,
    POST,
    reportDetails
  );
}

export const SEND_REPORTS = makeAsyncActionSet('SEND_REPORTS');
export function sendReports(data) {
  return makeRsaAction(SEND_REPORTS, `/reports/send/`, POST, data.toJS());
}

export const GET_REPORT_EMAIL_DEFAULTS = makeAsyncActionSet(
  'GET_REPORT_EMAIL_DEFAULTS'
);
export function getReportEmailDefaults() {
  return makeRsaAction(
    GET_REPORT_EMAIL_DEFAULTS,
    '/reports/email-defaults/',
    GET
  );
}

export const SET_POLL = 'SET_POLL';
export function setPoll(name, callback, rate) {
  return { type: SET_POLL, payload: { name, callback, rate } };
}

export const CLEAR_POLL = 'CLEAR_POLL';
export function clearPoll(name) {
  return { type: CLEAR_POLL, payload: { name } };
}

export const APPLY_TEMPLATE = makeAsyncActionSet('APPLY_TEMPLATE');
export function applyTemplate(activityId, templateId) {
  return makeRsaAction(
    APPLY_TEMPLATE,
    `/activities/${activityId}/apply-template/`,
    POST,
    { template: templateId }
  );
}

export const TOGGLE_REVEAL_PASSWORD = 'TOGGLE_REVEAL_PASSWORD';
export function toggleRevealPassword(field, shouldReveal) {
  return { type: TOGGLE_REVEAL_PASSWORD, payload: { field, shouldReveal } };
}

export const SEND_HEART_BEAT = makeAsyncActionSet('SEND_HEART_BEAT');
export function sendHeartBeat(productVersion, activity) {
  return makeRsaAction(
    SEND_HEART_BEAT,
    `/activities/${activity}/product-version/${productVersion}/heartbeat/`,
    POST,
    { productVersion, activity }
  );
}

export const SHARE_ACCESS_WITH_USER = makeAsyncActionSet(
  'SHARE_ACCESS_WITH_USER'
);
export function shareAccessWithUser(userId) {
  return makeRsaAction(
    SHARE_ACCESS_WITH_USER,
    `/accounts/share-access/`,
    POST,
    { user: userId }
  );
}

export const REVOKE_ACCESS_FROM_USER = makeAsyncActionSet(
  'REVOKE_ACCESS_FROM_USER'
);
export function revokeAccessFromUser(userId) {
  return makeRsaAction(
    REVOKE_ACCESS_FROM_USER,
    `/accounts/revoke-access/`,
    POST,
    { user: userId }
  );
}

export const DISMISS_SHARE_NOTIFICATIONS = makeAsyncActionSet(
  'DISMISS_SHARE_NOTIFICATIONS'
);
export function dismissShareNotifications(notifications) {
  return makeRsaAction(
    DISMISS_SHARE_NOTIFICATIONS,
    `/accounts/share-notification/dismiss/`,
    PUT,
    { notifications: notifications }
  );
}

export const GET_STRIPE_INVOICE = makeAsyncActionSet('GET_STRIPE_INVOICE');
export function getStripeInvoice(organisation, credits) {
  return makeRsaAction(
    GET_STRIPE_INVOICE,
    `/organisations/${organisation}/credit-purchase-stripe-invoice/`,
    POST,
    { credits }
  );
}

export const GET_STRIPE_SHOP_INVOICE = makeAsyncActionSet(
  'GET_STRIPE_SHOP_INVOICE'
);
export function getStripeShopInvoice() {
  return makeRsaAction(GET_STRIPE_SHOP_INVOICE, `/shop/checkout/`, POST);
}

export const ADD_CREDITS_TO_ORGANISATION = makeAsyncActionSet(
  'ADD_CREDITS_TO_ORGANISATION'
);
export function addCredits(id, amount, invoice) {
  return makeRsaAction(
    ADD_CREDITS_TO_ORGANISATION,
    `/organisations/${id}/add-credit/`,
    POST,
    {
      amount,
      invoice,
    }
  );
}

export const CREATE_REPORT_GENERATION_BATCH = makeAsyncActionSet(
  'CREATE_REPORT_GENERATION_BATCH'
);
export function createReportGenerationBatch(data) {
  return makeRsaAction(
    CREATE_REPORT_GENERATION_BATCH,
    `/activities/report-generation-batches/`,
    POST,
    data
  );
}

export const UPDATE_REPORT_GENERATION_BATCH = makeAsyncActionSet(
  'UPDATE_REPORT_GENERATION_BATCH'
);
export function updateReportGenerationBatch(id, data) {
  return makeRsaAction(
    UPDATE_REPORT_GENERATION_BATCH,
    `/activities/report-generation-batches/${id}/`,
    PATCH,
    data
  );
}

export const SET_MANUAL_REPORT_GENERATION = makeAsyncActionSet(
  'SET_MANUAL_REPORT_GENERATION'
);
export function setManualReportGeneration(activityId) {
  return makeRsaAction(
    SET_MANUAL_REPORT_GENERATION,
    `/activities/${activityId}/report-generation-manual/`,
    POST,
    undefined,
    { activityId }
  );
}

export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export function setPageSize(pageSize) {
  return { type: SET_PAGE_SIZE, payload: pageSize };
}

export const RESET_PAGE_SIZE = 'RESET_PAGE_SIZE';
export function resetPageSize(pageSize) {
  return { type: RESET_PAGE_SIZE, payload: pageSize };
}

export const RESET_PRODUCT_VERSION_ANSWERS = 'RESET_PRODUCT_VERSION_ANSWERS';
export function resetProductVersionAnswers() {
  return { type: RESET_PRODUCT_VERSION_ANSWERS };
}

export const SET_FORM_STAGE = 'SET_FORM_STAGE';
export function setFormStage(formName, stage) {
  return { type: SET_FORM_STAGE, payload: { formName, stage } };
}

export const RESET_FORM_STAGE = 'RESET_FORM_STAGE';
export function resetFormStage(formName) {
  return { type: RESET_FORM_STAGE, payload: { formName } };
}

export const RESET_GENERATING_REPORTS = 'RESET_GENERATING_REPORTS';
export function resetGeneratingReports() {
  return { type: RESET_GENERATING_REPORTS };
}

export const RESET_GET_REPORTS_FROM_DATE = 'RESET_GET_REPORTS_FROM_DATE';
export function resetGetReportsFromDate() {
  return { type: RESET_GET_REPORTS_FROM_DATE };
}

export const SET_MODAL_CLOSABLE = 'SET_MODAL_CLOSABLE';
export function setModalClosable(isClosable) {
  return { type: SET_MODAL_CLOSABLE, payload: isClosable };
}

export const SET_MODAL_TITLE = 'SET_MODAL_TITLE';
export function setModalTitle(title) {
  return { type: SET_MODAL_TITLE, payload: title };
}

export const SET_MODAL_ON_CLOSE_EVENT = 'SET_MODAL_ON_CLOSE_EVENT';
export function setModalOnCloseEvent(event) {
  return { type: SET_MODAL_ON_CLOSE_EVENT, payload: event };
}

export const SHOW_AUTO_LOGOUT_OVERLAY = 'SHOW_AUTO_LOGOUT_OVERLAY';
export function showAutoLogoutOverlay() {
  return { type: SHOW_AUTO_LOGOUT_OVERLAY };
}

export const HIDE_AUTO_LOGOUT_OVERLAY = 'HIDE_AUTO_LOGOUT_OVERLAY';
export function hideAutoLogoutOverlay() {
  return { type: HIDE_AUTO_LOGOUT_OVERLAY };
}

export const GET_ALL_ORGS = makeAsyncActionSet('GET_ALL_ORGS');
export function getAllOrgs(filters) {
  return makeRsaAction(GET_ALL_ORGS, `/organisations/list/`, GET, {
    fetch_all: true,
    ...filters,
  });
}

export const LOAD_ORG_USERS = makeAsyncActionSet('LOAD_ORG_USERS');
export function loadOrgUsers(orgId) {
  return makeRsaAction(
    LOAD_ORG_USERS,
    `/organisations/${orgId}/users/list/`,
    GET
  );
}

export const LOAD_ORG_PRODUCTS = makeAsyncActionSet('LOAD_ORG_PRODUCTS');
export function loadOrgProducts(orgId, user) {
  return makeRsaAction(
    LOAD_ORG_PRODUCTS,
    `/organisations/${orgId}/products/`,
    GET,
    null,
    {
      user,
    }
  );
}

export const LOAD_ORG_SESSIONS_RECENT = makeAsyncActionSet(
  'LOAD_ORG_SESSIONS_RECENT'
);
export function loadOrgSessionsRecent(orgId) {
  return makeRsaAction(
    LOAD_ORG_SESSIONS_RECENT,
    `/organisations/${orgId}/sessions/recent/`,
    GET,
    { fetch_all: true }
  );
}

export const LOAD_ORG_SESSIONS = makeAsyncActionSet('LOAD_ORG_SESSIONS');
export function loadOrgSessions(orgId, user, page = 1) {
  return dispatch =>
    dispatch(
      makeRsaAction(
        LOAD_ORG_SESSIONS,
        `/organisations/${orgId}/sessions/`,
        GET,
        { page, page_size: 100 },
        {
          user,
        }
      )
    ).then(({ payload }) => {
      // Load subsequent pages of sessions if there are any
      if (payload.next) {
        return dispatch(loadOrgSessions(orgId, user, page + 1));
      }
    });
}

export const CLEAR_ORG_FILTERS = 'CLEAR_ORG_FILTERS';
export const clearOrgFilters = () => ({
  type: CLEAR_ORG_FILTERS,
});

export const LOAD_ORG_FILTERS = makeAsyncActionSet('LOAD_ORG_FILTERS');
export function loadOrgFilters(orgId, params) {
  return makeRsaAction(
    LOAD_ORG_FILTERS,
    `/organisations/${orgId}/filters/`,
    GET,
    params
  );
}

export const TOGGLE_REPORTS_TABLE_SELECTION = 'TOGGLE_REPORTS_TABLE_SELECTION';
export function toggleReportsTableSelection(selection) {
  return { type: TOGGLE_REPORTS_TABLE_SELECTION, payload: selection };
}

export const TOGGLE_SELECT_ALL_REPORTS_TABLE_SELECTION =
  'TOGGLE_SELECT_ALL_REPORTS_TABLE_SELECTION';
export function toggleSelectAllReportsTableSelection(key) {
  return { type: TOGGLE_SELECT_ALL_REPORTS_TABLE_SELECTION, payload: key };
}

export const UNSELECT_ALL_REPORTS_TABLE_SELECTION =
  'UNSELECT_ALL_REPORTS_TABLE_SELECTION';
export const unselectAllReportsTableSelection = () => ({
  type: UNSELECT_ALL_REPORTS_TABLE_SELECTION,
});

export const FILTER_HIDDEN_REPORTS_TABLE_SELECTION =
  'FILTER_HIDDEN_REPORTS_TABLE_SELECTION';
export function filterHiddenReportsTableSelection(visibleSessionIds) {
  return {
    type: FILTER_HIDDEN_REPORTS_TABLE_SELECTION,
    payload: visibleSessionIds,
  };
}

export const LOAD_TEAM_REPORTS = makeAsyncActionSet('LOAD_TEAM_REPORTS');
export function loadTeamReports(organisation, user) {
  return makeRsaAction(
    LOAD_TEAM_REPORTS,
    `/reports/teams/`,
    GET,
    {
      organisation,
    },
    {
      user,
    }
  );
}

export const LOAD_PROFILE_SESSIONS_RECENT = makeAsyncActionSet(
  'LOAD_PROFILE_SESSIONS_RECENT'
);
export function loadProfileSessionsRecent(orgId) {
  return makeRsaAction(
    LOAD_PROFILE_SESSIONS_RECENT,
    `/organisations/${orgId}/sessions/profile/recent/`,
    GET,
    { fetch_all: true }
  );
}

export const LOAD_PROFILE_SESSIONS = makeAsyncActionSet(
  'LOAD_PROFILE_SESSIONS'
);
export function loadProfileSessions(organisation) {
  return makeRsaAction(
    LOAD_PROFILE_SESSIONS,
    `/organisations/${organisation}/sessions/profile/`,
    GET,
    {
      fetch_all: true,
    }
  );
}

export const LOAD_DISC_SESSIONS_PAGE = makeAsyncActionSet(
  'LOAD_DISC_SESSIONS_PAGE'
);
export function loadDiscSessionsPage(orgId, params, page) {
  return makeRsaAction(
    LOAD_DISC_SESSIONS_PAGE,
    `/organisations/${orgId}/sessions/disc/`,
    GET,
    { page, page_size: 100, ...params },
    { page }
  );
}

export function loadDiscSessions(orgId, params, page = 1) {
  return dispatch =>
    dispatch(loadDiscSessionsPage(orgId, params, page)).then(({ payload }) => {
      // Load subsequent pages of sessions if there are any
      if (payload.next) {
        return dispatch(loadDiscSessions(orgId, params, page + 1));
      }
    });
}

export const GET_REPORTS = makeAsyncActionSet('GET_REPORTS');
export function getReports(reportIds) {
  return makeRsaAction(GET_REPORTS, `/reports/`, GET, {
    reports: reportIds,
  });
}

export const ADD_PRODUCT_ORGANISATIONS = makeAsyncActionSet(
  'ADD_PRODUCT_ORGANISATIONS'
);
export function addProductOrganisations(activityId, productOrganisationIds) {
  return makeRsaAction(
    ADD_PRODUCT_ORGANISATIONS,
    `/activities/${activityId}/add-product-organisations/`,
    POST,
    { product_organisations: productOrganisationIds }
  );
}

export const DELETE_ACTIVITY_PRODUCT_VERSION = makeAsyncActionSet(
  'DELETE_ACTIVITY_PRODUCT_VERSION'
);
export function deleteActivityProductVersion(activityProductVersionId) {
  return makeRsaAction(
    DELETE_ACTIVITY_PRODUCT_VERSION,
    `/activities/activity-product-version/${activityProductVersionId}/`,
    DELETE,
    {},
    { id: activityProductVersionId }
  );
}

export const GET_QUICK_SUPPORT_GUIDE = makeAsyncActionSet(
  'GET_QUICK_SUPPORT_GUIDE'
);
export function getQuickSupportGuide() {
  return makeRsaAction(
    GET_QUICK_SUPPORT_GUIDE,
    `/help/get-quick-support-guide/`,
    GET
  );
}

export const INVITE_TO_STAGE_TWO = makeAsyncActionSet('INVITE_TO_STAGE_TWO');

export function inviteToStageTwo(activityId, sessionIds) {
  return makeRsaAction(
    INVITE_TO_STAGE_TWO,
    `/activities/${activityId}/invite-to-stage-2/`,
    POST,
    { sessions: sessionIds }
  );
}

export const LOAD_TEAM_SESSIONS_PAGE = makeAsyncActionSet(
  'LOAD_TEAM_SESSIONS_PAGE'
);
export function loadTeamSessionsPage(orgId, params, page) {
  return makeRsaAction(
    LOAD_TEAM_SESSIONS_PAGE,
    `/organisations/${orgId}/sessions/team/`,
    GET,
    { page, page_size: 100, ...params },
    { page }
  );
}

export function loadTeamSessionsPageThen(orgId, callback) {
  return dispatch =>
    dispatch(loadTeamSessionsPage(orgId, {}, 1)).then(callback);
}

export function loadTeamSessions(orgId, params, page = 1) {
  return dispatch =>
    dispatch(loadTeamSessionsPage(orgId, params, page)).then(({ payload }) => {
      // Load subsequent pages of sessions if there are any
      if (payload.next) {
        return dispatch(loadTeamSessions(orgId, params, page + 1));
      }
    });
}

export const GENERATE_TEAM_REPORT = makeAsyncActionSet('GENERATE_TEAM_REPORT');
export function generateTeamReport(reportDetails) {
  return makeRsaAction(
    GENERATE_TEAM_REPORT,
    `/reports/generate-team/`,
    POST,
    reportDetails
  );
}

export const ADD_PULSING_TO_ACTIVITY = makeAsyncActionSet(
  'ADD_PULSING_TO_ACTIVITY'
);
export const addPulsingToActivity = activityProductVersionId =>
  makeRsaAction(
    ADD_PULSING_TO_ACTIVITY,
    '/pulses/add-pulsing-to-activity/',
    POST,
    { activity_product_version_id: activityProductVersionId }
  );

export const START_PULSES = makeAsyncActionSet('START_PULSES');
export function startPulses(
  users,
  pulseSubscriptionName,
  reloadPulse,
  pulseId,
  invoice
) {
  return dispatch =>
    dispatch(
      makeRsaAction(START_PULSES, `/pulses/${pulseId}/create/`, POST, {
        users: users,
        pulse_subscription_name: pulseSubscriptionName,
        invoice,
        frequency: PulseFrequency.DAILY,
        frequency_count: 1,
        reminder_time_utc: DEFAULT_PULSE_TIME,
      })
    ).then(reloadPulse);
}

export const STOP_PULSES = makeAsyncActionSet('STOP_PULSES');

export function stopPulses(usersPulseIds, reloadPulse) {
  return dispatch =>
    dispatch(
      makeRsaAction(STOP_PULSES, `/pulses/stop/`, PUT, {
        user_pulses: usersPulseIds,
      })
    ).then(reloadPulse);
}

export const RESEND_PULSE_INVITES = makeAsyncActionSet('RESEND_PULSE_INVITES');
export function resendPulseInvites(pulseId, usersPulseIds) {
  return dispatch =>
    dispatch(
      makeRsaAction(
        RESEND_PULSE_INVITES,
        `/pulses/${pulseId}/resend-invites/`,
        POST,
        {
          user_pulses: usersPulseIds,
        }
      )
    );
}

export const SET_PULSE_BEHAVIOURS = makeAsyncActionSet('SET_PULSE_BEHAVIOURS');
export function setPulseBehaviours(id, behaviours, thenFunc) {
  return dispatch =>
    dispatch(
      makeRsaAction(
        SET_PULSE_BEHAVIOURS,
        `/pulses/user-pulse/${id}/set-behaviours/`,
        POST,
        behaviours
      )
    ).then(thenFunc);
}

export const SET_PULSE_RATERS = makeAsyncActionSet('SET_PULSE_RATERS');
export function setPulseRaters(id, raters, thenFunc) {
  return dispatch =>
    dispatch(
      makeRsaAction(
        SET_PULSE_RATERS,
        `/pulses/user-pulse/${id}/set-raters/`,
        POST,
        raters
      )
    ).then(thenFunc);
}

export const LOAD_USER_PULSE_RATER = makeAsyncActionSet(
  'LOAD_USER_PULSE_RATER'
);

export function loadUserPulseRater(id) {
  return makeRsaAction(
    LOAD_USER_PULSE_RATER,
    `/pulses/user-pulse-rater/${id}/`,
    GET
  );
}

export const DELETE_PULSE_RATER = makeAsyncActionSet('DELETE_PULSE_RATER');
export function deletePulseRater(id, thenFunc) {
  return dispatch =>
    dispatch(
      makeRsaAction(
        DELETE_PULSE_RATER,
        `/pulses/user-pulse-rater/${id}/delete/`,
        DELETE
      )
    ).then(thenFunc);
}

export const CLEAR_PULSE_RATER = makeAsyncActionSet('CLEAR_PULSE_RATER');
export function clearPulseRater(id, thenFunc) {
  return dispatch =>
    dispatch(
      makeRsaAction(
        CLEAR_PULSE_RATER,
        `/pulses/user-pulse-rater/${id}/clear/`,
        DELETE
      )
    ).then(thenFunc);
}

export const DELETE_PULSE_RATER_EXTERNAL = makeAsyncActionSet(
  'DELETE_PULSE_RATER_EXTERNAL'
);
export function deletePulseRaterExternal(id) {
  return dispatch =>
    dispatch(
      makeRsaAction(
        DELETE_PULSE_RATER_EXTERNAL,
        `/pulses/user-pulse-rater/${id}/delete-external/`,
        DELETE
      )
    );
}

export const SUBMIT_USER_PULSE_RATINGS = makeAsyncActionSet(
  'SUBMIT_USER_PULSE_RATINGS'
);
export function submitUserPulseRatings(id, values) {
  return makeRsaAction(
    SUBMIT_USER_PULSE_RATINGS,
    `/pulses/user-pulse-rater/${id}/update/`,
    POST,
    values
  );
}

export const CLEAR_USER_PULSE_DETAIL = 'CLEAR_USER_PULSE_DETAIL';
export const clearUserPulseDetail = () => ({
  type: CLEAR_USER_PULSE_DETAIL,
});

export const LOAD_USER_PULSE_DETAIL = makeAsyncActionSet(
  'LOAD_USER_PULSE_DETAIL'
);

export function loadUserPulseDetail(id, frequency) {
  return makeRsaAction(
    LOAD_USER_PULSE_DETAIL,
    `/pulses/user-pulse/${id}/detail/?frequency=${frequency.type}&frequency_count=${frequency.count}`,
    GET
  );
}

export const LOAD_BEHAVIOUR_OPTIONS = makeAsyncActionSet(
  'LOAD_BEHAVIOUR_OPTIONS'
);

export function loadBehaviourOptions(id) {
  return makeRsaAction(
    LOAD_BEHAVIOUR_OPTIONS,
    `/pulses/user-pulse/${id}/behaviour-options/`,
    GET
  );
}

export const CREATE_PULSE = makeAsyncActionSet('CREATE_PULSE');
export function createPulse(data) {
  return makeRsaAction(CREATE_PULSE, `/pulses/`, POST, data);
}

export const UPDATE_PULSE = makeAsyncActionSet('UPDATE_PULSE');
export const updatePulse = (id, data) => {
  return makeRsaAction(UPDATE_PULSE, `/pulses/${id}/`, PUT, data);
};

export const GET_ORG_FLEXI_PULSE_NAMES = makeAsyncActionSet(
  'GET_ORG_FLEXI_PULSE_NAMES'
);
export const getOrgFlexiPulseNames = id => {
  return makeRsaAction(
    GET_ORG_FLEXI_PULSE_NAMES,
    `/pulses/organisation-flexi-names/${id}/`,
    GET
  );
};

export const GET_CONSENT_POLICY = makeAsyncActionSet('GET_CONSENT_POLICY');
export const getConsentPolicy = langCode => {
  return makeRsaAction(
    GET_CONSENT_POLICY,
    `/consent/current-policy/${langCode}`,
    GET
  );
};

export const SUBMIT_CONSENT_DISAGREE = makeAsyncActionSet(
  'SUBMIT_CONSENT_DISAGREE'
);
export const submitConsentDisagree = policyId => {
  return makeRsaAction(
    SUBMIT_CONSENT_DISAGREE,
    `/consent/${policyId}/disagree/`,
    POST
  );
};

export const SUBMIT_CONSENT_AGREE = makeAsyncActionSet('SUBMIT_CONSENT_AGREE');
export const submitConsentAgree = policyId => {
  return makeRsaAction(
    SUBMIT_CONSENT_AGREE,
    `/consent/${policyId}/agree/`,
    POST
  );
};

export const UPDATE_USER_LANGUAGE = makeAsyncActionSet('UPDATE_USER_LANGUAGE');
export const updateUserLanguage = langCode => {
  return makeRsaAction(UPDATE_USER_LANGUAGE, '/accounts/me/', PATCH, {
    lang_code: langCode,
  });
};

export const GET_ACTIVITY_DETAIL = makeAsyncActionSet('GET_ACTIVITY_DETAIL');
export const getActivityDetail = activityId => {
  return makeRsaAction(GET_ACTIVITY_DETAIL, `/activities/${activityId}/`, GET);
};

export const SET_NOTIFICATION_EMAIL_LANGUAGE_PREFERENCE = makeAsyncActionSet(
  'SET_NOTIFICATION_EMAIL_LANGUAGE_PREFERENCE'
);
export const setNotificationEmailLanguagePreference = (
  activityId,
  notificationType,
  lang_code
) => {
  return makeRsaAction(
    SET_NOTIFICATION_EMAIL_LANGUAGE_PREFERENCE,
    `/activities/${activityId}/${notificationType}/notification-email-language/`,
    PUT,
    { lang_code }
  );
};

export const GET_SIMPLE_ORGANISATION = makeAsyncActionSet(
  'GET_SIMPLE_ORGANISATION'
);
export const getSimpleOrganisation = id => {
  return makeRsaAction(
    GET_SIMPLE_ORGANISATION,
    `/organisations/${id}/simple/`,
    GET
  );
};

export const RESET_SIMPLE_ORGANISATION = 'RESET_SIMPLE_ORGANISATION';
export const resetSimpleOrganisation = () => ({
  type: RESET_SIMPLE_ORGANISATION,
});

export const GET_PULSE_RESPONDENTS_SUMMARY = makeAsyncActionSet(
  'GET_PULSE_RESPONDENTS_SUMMARY'
);
export const getPulseRespondentsSummary = id => {
  return makeRsaAction(
    GET_PULSE_RESPONDENTS_SUMMARY,
    `/shop/purchased-pulses/${id}/respondents-summary/`,
    GET
  );
};

export const downloadBasketItemsCsv = (token, filterSpec, ordering) => {
  const queryString = toQueryString({
    JWT: token,
    ...filterSpec,
    ordering: ordering,
  });
  const url = `${API_PREFIX}/shop/basket-items/csv-export/?${queryString}`;
  window.open(url);
};

export const downloadPulsePurchasesCsv = (
  token,
  filterSpec,
  ordering,
  searchString
) => {
  const queryString = toQueryString({
    JWT: token,
    search: searchString,
    ordering: ordering,
    ...filterSpec,
  });
  const url = `${API_PREFIX}/shop/purchased-pulses/csv-export/?${queryString}`;
  window.open(url);
};

export const UPDATE_SEARCH_BAR_FORM = 'UPDATE_SEARCH_BAR_FORM';
export const updateSearchBarForm = (formKey, searchString) => ({
  type: UPDATE_SEARCH_BAR_FORM,
  field: formKey,
  value: searchString,
});
