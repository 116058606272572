import React, { PureComponent } from 'react'; // eslint-disable-line no-unused-vars
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { bindActionCreators } from 'redux';

import { setModalTitle } from '^/actions/actions';
import { RULES, getReportVisibility } from '^/reportVisibility';
import { can, onlySeeSurvey } from '^/capabilities';
import { anyPending, hasFailed, hasSucceeded } from '^/responseStates';
import { withRouter } from '^/withRouter';
import Alert, { AlertType } from '^/components/Alert';
import ProgressBar from '^/components/ProgressBar';
import { closeAllModals } from '^/actions/modals';
import IndeterminateProgressBar from '^/components/IndeterminateProgressBar';
import { ACTIVITY_TYPE } from '^/models/activity';
import { PRODUCT_TYPES } from '^/components/productVersions/choices';

const SHOW_MODAL_FOR = 4000;

export class CompleteModal extends PureComponent {
  componentDidUpdate(prevProps) {
    const { user, anyLoading, answersSaved, isTimeToStop } = this.props;
    const stoppedLoading = prevProps.anyLoading && anyLoading === false;
    const answersJustSaved = prevProps.answersSaved === false && answersSaved;
    if (stoppedLoading && answersJustSaved) {
      setTimeout(() => {
        this.props.setModalTitle(
          i18next.t('Your answers have been successfully submitted.')
        );
        if (!can(user, onlySeeSurvey()) || !isTimeToStop) {
          setTimeout(this.props.connectedOnContinue, SHOW_MODAL_FOR);
        }
      }, SHOW_MODAL_FOR);
    }
  }

  getReportHint(rule) {
    switch (rule) {
      case RULES.SHOW_ON_COMPLETION:
        return i18next.t(
          'You will receive your report via email in the next few minutes.'
        );
      case RULES.SHOW_AFTER_DATE:
        return i18next.t(
          'Please contact your activity administrator with any questions regarding your report.'
        );
    }
  }

  render() {
    const {
      user,
      activity,
      productVersion,
      raterFor,
      answersSaved,
      anyLoading,
      isTimeToStop,
    } = this.props;
    const completionTextOverride = productVersion.get(
      'completion_text_override'
    );
    const reportVisibility = getReportVisibility(activity);
    const showProgressBar = anyLoading || !answersSaved;
    const showReportHint = !showProgressBar && !raterFor && isTimeToStop;
    const isDISCProfiler =
      this.props.activity.get('type') === ACTIVITY_TYPE.PROFILER &&
      this.props.productVersion.get('product').get('type') ===
        PRODUCT_TYPES.CHOICES.DISC;

    const isPerspectives =
      this.props.productVersion.getIn(['product', 'type']) ===
      PRODUCT_TYPES.CHOICES.PERSPECTIVES;
    const reportHint = this.getReportHint(reportVisibility.get('rule'));

    if (completionTextOverride) {
      return <ReactMarkdown source={completionTextOverride} />;
    }
    if (isDISCProfiler && !this.props.failedToSave) {
      this.props.setModalTitle(
        i18next.t('Thank you for completing the DISC Discovery Job Profiler.')
      );
    }
    if (isDISCProfiler && this.props.failedToSave) {
      this.props.setModalTitle(i18next.t('Invalid profile'));
    }

    return (
      <div>
        {showProgressBar && !this.props.failedToSave && !isPerspectives && (
          <p>
            {i18next.t('Please wait while your answers are being submitted.')}
          </p>
        )}
        {can(user, onlySeeSurvey()) &&
          isTimeToStop &&
          i18next.t('You may now close this tab.')}

        {showReportHint && reportHint && <p>{reportHint}</p>}
        {!this.props.failedToSave ? (
          showProgressBar ? (
            <IndeterminateProgressBar />
          ) : (
            <ProgressBar percent={100} />
          )
        ) : (
          ''
        )}
        {isDISCProfiler && this.props.failedToSave && (
          <>
            <Alert type={AlertType.Error}>
              {i18next.t(
                'Invalid profile - Your responses suggest a lack of discrimination'
              )}
            </Alert>
            <button
              onClick={() => this.props.closeAllModals()}
              className="btn btn-primary"
            >
              {i18next.t('Redo profile')}
            </button>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
    answersSaved: hasSucceeded(
      state.responses.get('updateActivityProductVersionSession')
    ),
    failedToSave: hasFailed(
      state.responses.get('updateActivityProductVersionSession')
    ),
    anyLoading: anyPending([
      state.responses.get('updateActivityProductVersionSession'),
      state.responses.get('answerQuestion'),
    ]),
  };
}

function matchDispatchToProps(dispatch, props) {
  const actions = bindActionCreators(
    {
      setModalTitle,
      closeAllModals,
    },
    dispatch
  );
  return Object.assign({}, actions, {
    connectedOnContinue: () => dispatch(props.onContinue()),
  });
}

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(CompleteModal)
);
