import React from 'react'; // eslint-disable-line no-unused-vars
import { List, Set } from 'immutable';
import i18next from 'i18next';

import { ACTIVITY_STATUS } from '^/activityStatus';
import PureComponent from '^/components/PureComponent';
import LoadingAndErrors from '^/components/LoadingAndErrors';
import InviteToStageTwoButton from '^/components/activities/tabs/users/InviteToStageTwoButton';
import UsersTable from './UsersTable';
import GoToReportsLink from './GoToReportsLink';

export default class ProfileCreatorsTab extends PureComponent {
  constructor(props) {
    super(props);
    this.setProfileCreatorEmail = this.setProfileCreatorEmail.bind(this);
    this.state = {
      profileCreatorEmail: '',
    };
  }

  setProfileCreatorEmail(email) {
    this.setState({ profileCreatorEmail: email });
  }

  editUser() {
    const { activityUsers, organisation } = this.props;
    const currentIds = activityUsers.map(each => each.getIn(['user', 'id']));

    const updateUsers = userIds => {
      userIds = userIds.subtract(currentIds);
      this.props.addProfileCreatorToActivityAndRefresh(
        this.props.activityId,
        currentIds,
        userIds.toArray()
      );
    };

    this.props.openAddProfileRatersModal(
      Set(currentIds),
      1,
      updateUsers,
      this.setProfileCreatorEmail,
      organisation.get('id')
    );
  }

  render() {
    const {
      activity,
      activityId,
      activityUsers,
      status,
      readOnly,
      organisation,
      addUsersResponse,
      addGroupsResponse,
      removeUserResponse,
      activitySummary,
    } = this.props;

    const withPulse = activitySummary.get('with_pulse');
    const isEnded = status === ACTIVITY_STATUS.ENDED;
    return (
      <div title={i18next.t('Profile raters')}>
        <div className="clearfix">
          <div className="pull-right">
            {!readOnly && !isEnded && activitySummary.get('is_staged') && (
              <InviteToStageTwoButton {...this.props} />
            )}
            <GoToReportsLink
              activityId={activityId}
              organisationId={organisation.get('id')}
              isProfiler
            />
          </div>
        </div>

        {this.props.children}

        <UsersTable
          activity={activity}
          activityUsers={activityUsers}
          activityId={activityId}
          editUser={() => this.editUser()}
          withPulse={withPulse}
          readOnly={readOnly}
          isProfiler
        />

        <LoadingAndErrors
          responses={List([
            addUsersResponse,
            removeUserResponse,
            addGroupsResponse,
          ])}
        />
      </div>
    );
  }
}
