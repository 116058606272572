/* Unfortunately, these actions need to know about react components
 */
import i18next from 'i18next';
import { List, Map } from 'immutable';
import React from 'react';

import { RESEND_NOTIFICATION_EMAIL } from '^/actions/actions';
import { CreateEditProfileDetailsFormProps } from '^/components/advanced-analytics/filter-profiles/CreateEditProfileDetailsForm';
import { MODAL_FOOTERS, ModalConfig, MODALS } from '^/components/modals/types';
import ProfileModalTitle from '^/components/profile/ProfileModalTitle';
import { PulseUserRow } from '^/components/pulses/PulsePage';
import { BasketItem } from '^/components/shop/basket/types';
import { getNeedsComparisonGroup } from '^/models/product';
import {
  AnalyticsAppliedFilters,
  AnalyticsProduct,
  FilterProfileDetail,
  HeatmapRow,
  PulseDisplayFrequency,
  PulseSubscriptionDetails,
  ShopItem,
  Uuid,
  ReportTemplate,
} from '^/reducers/api/types';
import { toProperCase } from '^/utils';
import { ACTIVITY_TYPE } from '^/models/activity';
import { PRODUCT_TYPES } from '^/components/productVersions/choices';

export const OPEN_MODAL = 'OPEN_MODAL';
export const OPEN_MODAL_AND_CLOSE_ALL_OTHERS =
  'OPEN_MODAL_AND_CLOSE_ALL_OTHERS';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_TOP_MODAL = 'CLOSE_TOP_MODAL';
export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';

const PULSE_MODAL_CLASSNAME = 'pulse-modal';

export function openModal(config: ModalConfig) {
  return {
    type: OPEN_MODAL,
    payload: config,
  };
}

const openModalAndCloseAllOthers = (config: ModalConfig) => ({
  type: OPEN_MODAL_AND_CLOSE_ALL_OTHERS,
  payload: config,
});

export function closeModal(modalId: MODALS) {
  return {
    type: CLOSE_MODAL,
    payload: modalId,
  };
}

export function closeTopModal(clearResponses = true) {
  return {
    type: CLOSE_TOP_MODAL,
    payload: {
      clearResponses,
    },
  };
}

export const closeAllModals = () => ({
  type: CLOSE_ALL_MODALS,
});

export const OPEN_ANOTHER_MODAL = 'OPEN_ANOTHER_MODAL';
export function openAnotherModal(config: ModalConfig) {
  return {
    type: OPEN_ANOTHER_MODAL,
    payload: config,
  };
}

export const OPEN_SLIDER_MODAL = 'OPEN_SLIDER_MODAL';
export function openSliderModal(
  onSliderValueUpdate: (from: string, to: string) => void,
  factor: string | undefined,
  attribute: string | undefined,
  from: string | undefined,
  to: string | undefined,
  product: AnalyticsProduct
) {
  return openAnotherModal({
    id: MODALS.ADVANCED_ANALYTICS_SET_VALUE_MODAL,
    className: 'slider-modal',
    props: {
      onSliderValueUpdate,
      factor,
      attribute,
      from,
      to,
      product,
    },
  });
}

export function openAdditionalDetailsModal(userDetails: Map<string, any>) {
  return openModal({
    title: <ProfileModalTitle />,
    id: MODALS.PROFILE_MODAL,
    props: { user: userDetails },
    closable: false,
  });
}

export function removeProductVersionFromActivityModal<FormData = {}>(
  productVersionName: string,
  submitAction: (data: FormData) => void
) {
  const body = (
    <div>
      <p>
        {i18next.t<string>('Are you sure you wish to remove this product?')}
      </p>
      <p>
        <strong>
          {i18next.t<string>(
            'You are about to remove {{productVersionName}} from this activity. Please click continue to confirm.',
            { productVersionName }
          )}
        </strong>
      </p>
    </div>
  );

  return openModal({
    title: i18next.t<string>('Remove product'),
    id: MODALS.GENERIC_MODAL,
    props: {
      confirmAction: submitAction,
      confirmItem: 'productVersion',
      confirmText: i18next.t<string>('Remove product'),
      submitAction,
      submitButtonText: i18next.t<string>('Remove product'),
      body,
      pendingMessage: i18next.t<string>('Removing {{productVersionName}}', {
        productVersionName,
      }),
    },
  });
}

export function openAdditionalDetailsThankYouModal() {
  return openModal({
    title: i18next.t<string>('Thank you for completing your details.'),
    id: MODALS.PROFILE_THANK_YOU_MODAL,
  });
}

export function openSudoModal() {
  return openModal({
    title: i18next.t<string>('View as another user'),
    id: MODALS.SUDO_MODAL,
  });
}

export function openCompletedProductVersionModal(
  activity: Map<string, any>,
  productVersion: Map<string, any>,
  raterFor: Map<string, any> | null,
  timeoutText: string,
  onContinue: () => any,
  isTimeToStop: boolean
) {
  const isDISCProfile =
    productVersion.getIn(['product', 'activity_type']) ===
      ACTIVITY_TYPE.PROFILER &&
    productVersion.getIn(['product', 'type']) === PRODUCT_TYPES.CHOICES.DISC;
  const productName = productVersion.getIn(['product', 'name']);
  const productCode = productVersion.getIn(['product', 'code']);
  const raterFullName = raterFor && raterFor.get('full_name');
  const fallbackTitle = raterFor
    ? i18next.t<string>(
        'Thank you for completing {{productName}} ({{productCode}}) for {{raterFullName}}',
        { productName, productCode, raterFullName }
      )
    : isDISCProfile
    ? ''
    : i18next.t<string>(
        'Thank you for completing {{productName}} ({{productCode}})',
        {
          productName,
          productCode,
        }
      );
  return openAnotherModal({
    title: timeoutText || fallbackTitle,
    className: 'modal-narrow',
    closable: false,
    id: MODALS.COMPLETE_MODAL,
    props: {
      activity,
      productVersion,
      raterFor,
      onContinue,
      isTimeToStop,
    },
  });
}

export function openProductVersionModal(
  productVersion: Map<string, any>,
  activity: Map<string, any>,
  raterFor: Map<string, any> | null,
  questionCollectionIdx = 0,
  ranking = false
) {
  return openModalAndCloseAllOthers({
    fullScreenOverlay: true,
    id: MODALS.ANSWER_PRODUCT_VERSION,
    props: {
      productVersion,
      activity,
      raterFor,
      questionCollectionIdx,
      ranking,
    },
    closable: false,
  });
}

export function openRespondentAssessmentChecklistModal(
  productVersion: Map<string, any>,
  activityId: Uuid,
  raterFor: Map<string, any> | null,
  questionCollectionIdx = 0
) {
  return openModal({
    fullScreenOverlay: true,
    id: MODALS.RESPONDENT_ASSESSMENT_CHECKLIST_MODAL,
    props: { productVersion, activityId, raterFor, questionCollectionIdx },
  });
}

export function openWelcomeModal(
  productVersion: Map<string, any>,
  activity: Map<string, any>,
  raterFor: Map<string, any> | null,
  questionCollection: Map<string, any> | null
) {
  return openModalAndCloseAllOthers({
    fullScreenOverlay: true,
    id: MODALS.WELCOME_MODAL,
    props: { productVersion, activity, raterFor, questionCollection },
  });
}

export function openPsycapPotentialWelcomeModal(
  productVersion: Map<string, any>,
  activity: Map<string, any>,
  raterFor: Map<string, any> | null
) {
  return openModal({
    fullScreenOverlay: true,
    id: MODALS.PSYCAP_POTENTIAL_WELCOME_MODAL,
    props: { productVersion, activity, raterFor },
  });
}

export function openPerspectivesWelcomeModal(
  productVersion: Map<string, any>,
  activity: Map<string, any>,
  raterFor: Map<string, any> | null
) {
  return openModal({
    fullScreenOverlay: true,
    id: MODALS.PERSPECTIVES_WELCOME_MODAL,
    props: { productVersion, activity, raterFor },
  });
}

export function resendNotificationCompleteModal(actionType: string) {
  const message =
    actionType === RESEND_NOTIFICATION_EMAIL.SUCCESS
      ? i18next.t<string>('The notification has been resent')
      : i18next.t<string>(
          'The notification failed to resend - please try again.'
        );

  return openModal({
    title: i18next.t<string>('Resend Notification'),
    body: <p>{message}</p>,
    footer: MODAL_FOOTERS.CLOSE_BUTTON_RIGHT,
  });
}

export function openConfirmationModal(
  verb: string,
  objectType: string,
  extraText: ReadonlyArray<string>,
  onConfirm: () => void,
  onClose: () => void,
  btnClass: string | null,
  overrideText?: ReadonlyArray<string> | null,
  buttonOverrideText?: string | null
) {
  const body = overrideText ? (
    <div>
      {overrideText.map((value, key) => (
        <p key={key}>{value}</p>
      ))}
    </div>
  ) : (
    <div>
      {objectType && (
        <p>
          {i18next.t<string>(
            'Are you sure you wish to {{verb}} this {{objectType}}?',
            {
              verb,
              objectType,
            }
          )}
        </p>
      )}
      {extraText.map((value, key) => (
        <p key={key}>{value}</p>
      ))}
    </div>
  );
  const verbObjectType = toProperCase(
    verb + (objectType ? ` ${objectType}` : '')
  );
  return openAnotherModal({
    className: 'modal-small',
    title: verbObjectType,
    body,
    footer: (
      <div className="pull-right">
        <button className="btn btn-default" onClick={onClose}>
          {i18next.t<string>('Cancel')}
        </button>
        <button className={'btn ' + (btnClass || '')} onClick={onConfirm}>
          {buttonOverrideText || verbObjectType}
        </button>
      </div>
    ),
  });
}

export function openProgressModal(
  isPsycapPotential: boolean,
  isStaged: boolean,
  remainingTime: string,
  completionText: string | null,
  onClose: () => void,
  onConfirm: () => void
) {
  const remainingTimeMessage =
    isPsycapPotential && !isStaged
      ? i18next.t<string>(
          'You have {{remainingTime}} remaining for the reasoning section',
          { remainingTime }
        )
      : i18next.t<string>(
          'You have {{remainingTime}} remaining for this assessment',
          {
            remainingTime,
          }
        );
  const confirmMessage =
    isPsycapPotential && !isStaged
      ? i18next.t<string>(
          'Please confirm you would like to mark this section as complete'
        )
      : i18next.t<string>(
          'Please confirm you would like to mark this assessment as complete'
        );
  return openAnotherModal({
    className: 'modal-small',
    closable: false,
    body: completionText ? (
      <p>{completionText}</p>
    ) : (
      <div>
        <p>{remainingTimeMessage}</p>
        <p>{confirmMessage}</p>
      </div>
    ),
    footer: (
      <div className="pull-right">
        <button className="btn btn-default" onClick={onClose}>
          {i18next.t<string>('Cancel')}
        </button>
        <button className="btn btn-primary" onClick={onConfirm}>
          {i18next.t<string>('Confirm')}
        </button>
      </div>
    ),
  });
}

export function openCreateUserModal() {
  return openModal({
    title: i18next.t<string>('Create a new user'),
    id: MODALS.CREATE_USER_MODAL,
  });
}

export function openConfirmRemoveModal(
  title: string,
  text: string,
  onConfirmFunction: () => void
) {
  return openModal({
    title: title,
    id: MODALS.CONFIRM_REMOVE_MODAL,
    props: {
      text: text,
      onConfirmFunction: onConfirmFunction,
    },
    className: PULSE_MODAL_CLASSNAME,
  });
}

export function openStartPulsingModal(
  users: PulseUserRow[] | undefined,
  pulseSubscriptionDetails: PulseSubscriptionDetails,
  reloadPulse: () => void,
  pulseId: Uuid
) {
  return openModal({
    title: i18next.t<string>('Start pulsing'),
    id: MODALS.START_PULSING_MODAL,
    props: {
      users: users,
      reloadPulse: reloadPulse,
      pulseId,
      pulseSubscriptionDetails,
    },
  });
}

export function openStopPulsingModal(
  users: ReadonlyArray<{
    userPulseId: Uuid;
    fullName: string;
  }>,
  reloadPulse: () => void
) {
  return openModal({
    title: i18next.t<string>('Cancel pulsing'),
    id: MODALS.STOP_PULSING_MODAL,
    props: {
      users: users,
      reloadPulse: reloadPulse,
    },
  });
}

export function openResendPulseInvitesModal(
  users: ReadonlyArray<{
    userPulseId: Uuid;
    fullName: string;
  }>,
  pulseId: Uuid
) {
  return openModal({
    title: i18next.t<string>('Resend Pulse invites'),
    id: MODALS.RESEND_PULSE_INVITES,
    props: {
      users: users,
      pulseId,
    },
  });
}

export function openCreateGroupModal(
  organisation?: string | Map<string, any>,
  organisations?: List<Map<string, any>>,
  filters?: AnalyticsAppliedFilters
) {
  return openModal({
    title: filters
      ? i18next.t<string>('Create group')
      : i18next.t<string>('Create a new group'),
    id: MODALS.CREATE_GROUP_MODAL,
    props: { organisation, organisations, filters },
  });
}

export function openImportUsersModal(groupId: Uuid | undefined) {
  const title = groupId ? 'Import users to group' : 'Import users';
  return openModal({
    title: i18next.t<string>(title),
    id: MODALS.IMPORT_USERS_MODAL,
    props: { groupId },
  });
}

export function openEmailEditModal<FormData>(
  // @TODO: Figure out what types redux form wants and remove these any types
  notification: Object,
  activityId: string | undefined,
  type: string,
  onSubmit?: (data: FormData) => void
) {
  return openAnotherModal({
    title: i18next.t<string>('Editing report email'),
    id: MODALS.EMAIL_EDIT_MODAL,
    props: { notification, activityId, type, onSubmit },
  });
}

export function openNotificationEmailEditModal(
  notification: Map<string, any>,
  title: string,
  activity: Map<string, any>
) {
  const activityId = activity.get('id');
  return openModal({
    title,
    id: MODALS.NOTIFICATION_EMAIL_EDIT_MODAL,
    props: { notification, activityId },
  });
}

export function openTokenExpiredModal(exportType: string, metadata: any) {
  return openAnotherModal({
    title: i18next.t<string>('Download generating'),
    id: MODALS.TOKEN_EXPIRED_MODAL,
    props: { exportType, metadata },
  });
}

export function openEditProductAccessModal(
  userId: Uuid,
  account: Map<string, any>,
  licenceHolders: List<Map<string, any>>
) {
  return openModal({
    title: i18next.t<string>('Edit admin'),
    id: MODALS.EDIT_ACCOUNT_ADMIN_PRODUCTS_MODAL,
    props: { userId, account, licenceHolders },
  });
}

export function openPurchaseCreditsModal(
  accountId: Uuid,
  onPurchaseComplete?: () => void
) {
  return openAnotherModal({
    title: i18next.t<string>('Purchase credits'),
    className: 'modal-narrow',
    id: MODALS.PURCHASE_CREDITS_MODAL,
    props: { accountId, onPurchaseComplete },
  });
}

export function openChooseShopItemVerificationTypeModal(shopItem: ShopItem) {
  return openModal({
    title: shopItem.name,
    id: MODALS.CHOOSE_SHOP_ITEM_VERIFICATION_TYPE_MODAL,
    props: { shopItem },
  });
}

export function openPaymentInProgressModal(
  isPurchasingCredits = false,
  paymentUrl: string,
  basketItemIds: ReadonlyArray<string>
) {
  return openModal({
    title: isPurchasingCredits
      ? i18next.t<string>(
          'Complete your credit purchase in the payment tab. You may close this message when complete.'
        )
      : i18next.t<string>(
          'Complete your purchase in the payment tab. You may close this message when complete.'
        ),
    className: 'modal-narrow',
    id: MODALS.PAYMENT_IN_PROGRESS_MODAL,
    closable: false,
    footer: MODAL_FOOTERS.CLOSE_BUTTON_RIGHT,
    props: {
      paymentUrl,
      isPurchasingCredits,
      basketItemIds,
    },
  });
}

export function openProductVersionsReportGenerationModal(
  activityProductVersions: List<Map<string, any>>,
  organisation: Map<string, any>
) {
  const someNeedComparisonGroup = activityProductVersions.some(
    getNeedsComparisonGroup
  );
  return openModal({
    title: someNeedComparisonGroup
      ? i18next.t<string>('Comparison groups and reports')
      : i18next.t<string>('Reports'),
    id: MODALS.PRODUCT_VERSIONS_REPORT_GENERATION_MODAL,
    props: { activityProductVersions, organisation },
  });
}

export function openEditCreditCostModal(currentCreditCost: Map<string, any>) {
  return openModal({
    title: i18next.t<string>('Edit global credit cost'),
    className: 'modal-narrow',
    id: MODALS.EDIT_CREDIT_COST_MODAL,
    props: { currentCreditCost },
  });
}

export function openEditBulkDiscountBandModal(
  bulkDiscountBandCollection: Map<string, any>
) {
  const id = bulkDiscountBandCollection.get('id');
  const name = bulkDiscountBandCollection.get('name');
  return openModal({
    title: i18next.t<string>('Add/edit discount bands', { name }),
    id: MODALS.EDIT_BULK_DISCOUNT_BAND_MODAL,
    props: { id },
  });
}

export function openDeleteBulkDiscountBandModal(
  bulkDiscountBandCollection: Map<string, any>
) {
  const name = bulkDiscountBandCollection.get('name');
  return openModal({
    title: i18next.t<string>('Delete {{name}}', { name }),
    className: 'modal-narrow',
    id: MODALS.DELETE_BULK_DISCOUNT_BAND_MODAL,
    props: { bulkDiscountBandCollection },
  });
}

export function openAddBulkDiscountBandModal() {
  return openModal({
    title: i18next.t<string>('Add new discounts'),
    className: 'modal-narrow',
    id: MODALS.ADD_BULK_DISCOUNT_BAND_MODAL,
  });
}

export function openConfirmAndUpdateBandCollectionModal(
  account: Map<string, any>,
  bulkDiscountBandCollection: Map<string, any>
) {
  return openModal({
    title: i18next.t<string>('Discount selected'),
    className: 'modal-narrow',
    id: MODALS.CONFIRM_UPDATE_BULK_DISCOUNT_BAND_MODAL,
    props: { account, bulkDiscountBandCollection },
  });
}

export function openUpgradeAccountTypeModal(
  account: Map<string, any>,
  nextAccountType: string
) {
  return openModal({
    title: i18next.t<string>('Upgrade account'),
    className: 'modal-narrow',
    id: MODALS.CONFIRM_UPGRADE_ACCOUNT_TYPE_MODAL,
    props: { account, nextAccountType },
  });
}

export function openCreditAddModal(
  account: Map<string, any>,
  onPurchaseComplete?: () => void
) {
  return openAnotherModal({
    title: i18next.t<string>('Add credits to organisation'),
    id: MODALS.ADD_CREDITS_TO_ORGANISATION_MODAL,
    props: { account, onPurchaseComplete },
  });
}

export function openAccountLogoModal(account: Map<string, any>) {
  return openModal({
    title: i18next.t<string>('Add account logo'),
    id: MODALS.EDIT_ACCOUNT_LOGO_MODAL,
    props: { account },
  });
}

export function openAddUsersModal(
  organisationId: Uuid,
  currentIds: List<string>,
  onComplete: (selectedUsers: List<string>) => void,
  asRespondents = true,
  maxNumMoreUsers?: number
) {
  return openModal({
    title: asRespondents
      ? i18next.t<string>('Add respondents')
      : i18next.t<string>('Add users'),
    id: MODALS.ADD_USERS_MODAL,
    props: {
      filter: { organisations: organisationId },
      currentIds,
      onComplete,
      hideOrganisations: true,
      asRespondents: true,
      hideGroups: true,
      maxNumMoreUsers,
    },
  });
}

export function openUpdateUsersModal(
  organisationId: Uuid,
  currentIds: List<string>,
  maxNumUsers: number | undefined,
  onComplete: (selectedUsers: List<string>) => void,
  props?: any
) {
  const title = props?.isProfile
    ? i18next.t<string>('Add profile raters')
    : i18next.t<string>('Add respondents');
  return openModal({
    id: MODALS.ADD_USERS_MODAL,
    title: title,
    props: {
      organisationId,
      filter: { organisations: organisationId },
      currentIds,
      maxNumUsers,
      hideOrganisations: true,
      onComplete,
      asRespondents: true,
      showInlineForm: true,
      allowRemove: true,
      hideGroups: true,
      showFilters: true,
      ...props,
    },
  });
}

export function openAddGroupsModal(
  organisation: Map<string, any>,
  currentUserIds: List<string>,
  onComplete: (
    selectedGroupIds: List<string>,
    selectedUserIds: List<string>,
    groupsSet?: boolean
  ) => void,
  maxNumMoreUsers?: number
) {
  return openModal({
    title: i18next.t<string>('Add groups'),
    id: MODALS.ADD_GROUPS_MODAL,
    props: {
      filter: { organisation: organisation.get('id') },
      onComplete,
      hideAccounts: true,
      maxNumMoreUsers,
      currentUserIds,
    },
  });
}

export function openPulseAddGroupsModal(
  organisationId: string | undefined,
  currentUserIds: List<string>,
  onComplete: (
    selectedGroupIds: List<string>,
    selectedUserIds: List<string>,
    groupsSet?: boolean
  ) => void,
  maxNumMoreUsers?: number
) {
  return openModal({
    title: i18next.t<string>('Add groups'),
    id: MODALS.ADD_GROUPS_MODAL,
    props: {
      filter: { organisation: organisationId },
      onComplete,
      hideAccounts: true,
      maxNumMoreUsers,
      currentUserIds,
    },
  });
}

export function openInviteToStageTwoModal(
  activityId: Uuid,
  activityUsers: List<any>
) {
  return openModal({
    title: i18next.t<string>('Invite to stage 2'),
    id: MODALS.INVITE_TO_STAGE_TWO_MODAL,
    props: {
      activityId,
      activityUsers,
    },
  });
}

export function openSendReportsModal(
  isTeamReport: boolean,
  isProfiler: boolean,
  reports: ReadonlyArray<Uuid>,
  onClose: () => void,
  isFlowTeamGeneration: boolean,
  selectedUserIds: ReadonlyArray<Uuid>
) {
  return openAnotherModal({
    title: i18next.t<string>('Send reports'),
    id: MODALS.SEND_REPORTS_MODAL,
    props: {
      reports,
      onClose,
      isTeamReport,
      isProfiler,
      isFlowTeamGeneration,
      selectedUserIds,
    },
  });
}

export function openReportGenerationModal(
  isProfiler?: boolean,
  organisationId?: Uuid,
  selectedReports?: ReadonlyArray<{ productId: Uuid; reportId: Uuid }>
) {
  return openModal({
    title: i18next.t<string>('Purchase Reports'),
    className: 'modal-narrow',
    id: MODALS.REPORT_GENERATION,
    props: { isProfiler, organisationId, selectedReports },
  });
}

export function openEditTaxDetailsModal(account: Map<string, any>) {
  return openModal({
    title: i18next.t<string>('Edit tax details'),
    className: 'modal-narrow',
    id: MODALS.EDIT_TAX_DETAILS,
    props: { account },
  });
}

export function openShopItemPurchaseConfirmationModal(
  purchasedBasketItems: BasketItem[]
) {
  return openModal({
    title: i18next.t<string>('Payment confirmed'),
    id: MODALS.SHOP_ITEM_PURCHASE_CONFIRMATION_MODAL,
    props: { purchasedBasketItems },
  });
}

export function openAdvancedAnalyticsModal(
  filters: AnalyticsAppliedFilters,
  product: AnalyticsProduct,
  initialCount: number,
  onApply: (resultsRanges: string[][]) => void
) {
  return openAnotherModal({
    title: i18next.t<string>('Advanced analytics'),
    id: MODALS.ADVANCED_ANALYTICS_MODAL,
    props: { onApply, filters, product, initialCount },
  });
}

export function openCreateTeamReportModal(productId: Uuid, orgId: Uuid) {
  return openModal({
    title: i18next.t<string>('Select responses for your team report'),
    id: MODALS.CREATE_TEAM_REPORT_MODAL,
    props: { orgId, productId },
  });
}

export function openCreateJobMatchReportsModal(
  profileSessionId: Uuid,
  orgId: Uuid,
  jobProfileReportTemplate: ReportTemplate,
  isJobProfileReportGenerated: boolean,
  roleName: string
) {
  return openModal({
    title: i18next.t<string>('Select responses to match to the job profile'),
    id: MODALS.CREATE_JOB_MATCH_REPORTS_MODAL,
    props: {
      profileSessionId,
      orgId,
      jobProfileReportTemplate,
      isJobProfileReportGenerated,
      roleName,
    },
  });
}

export function openSplitRowModal(
  row: HeatmapRow,
  indices: ReadonlyArray<number>,
  user: Immutable.Map<string, any>,
  inModal: boolean,
  parentFilters?: AnalyticsAppliedFilters
) {
  return openAnotherModal({
    title: i18next.t<string>('Split data set'),
    id: MODALS.SPLIT_ROW_MODAL,
    props: { row, indices, user, inModal, parentFilters },
  });
}

export function openDataAnalyticsOverlayModal(
  title: string,
  aggregate: HeatmapRow | null,
  editable: boolean,
  inModal: boolean,
  product?: Uuid,
  index?: number
) {
  return openAnotherModal({
    className: 'modal-wide full-height data-analytics',
    title,
    id: MODALS.DATA_ANALYTICS_OVERLAY_MODAL,
    props: { aggregate, product, editable, inModal, index },
  });
}

export function openDataAnalyticsHeatMapOverlayModal(
  title: string,
  aggregate: HeatmapRow | null,
  editable: boolean,
  inModal: boolean,
  product?: Uuid,
  index?: number
) {
  return openModal({
    className: 'modal-wide full-height data-analytics',
    title,
    id: MODALS.DATA_ANALYTICS_HEATMAP_OVERLAY_MODAL,
    props: { aggregate, product, editable, index, inModal },
  });
}

export function openSetTimeModal(
  value: string,
  onComplete: (value: string) => void
) {
  return openModal({
    title: 'Select a time',
    id: MODALS.SET_TIME_MODAL,
    props: {
      value,
      onComplete,
    },
  });
}

export function openSetFrequencyTimeModal(
  time: string,
  frequency: PulseDisplayFrequency,
  onComplete: (time: string, frequency: PulseDisplayFrequency) => void
) {
  return openModal({
    className: 'white-background-small',
    title: i18next.t<string>('Update Pulse frequency'),
    id: MODALS.SET_FREQUENCY_TIME_MODAL,
    props: {
      time,
      frequency,
      onComplete,
    },
  });
}

export function openPurchasedPulseBreakdownModal(pulseId: string) {
  return openModal({
    title: 'Invoice details',
    id: MODALS.PURCHASED_PULSE_BREAKDOWN_MODAL,
    props: {
      pulseId,
      modalId: MODALS.PURCHASED_PULSE_BREAKDOWN_MODAL,
    },
  });
}

export function openRequestStatusModal(
  responseKey: string,
  successText: string
) {
  return openAnotherModal({
    id: MODALS.REQUEST_STATUS_MODAL,
    props: {
      responseKey,
      successText,
    },
    className: 'white-square-modal',
    closable: false,
  });
}

export function openAdvancedAnalyticsConfirmModal(
  descriptionText: string,
  actionText: string,
  onDelete: () => void,
  actionButtonClass?: string
) {
  return openAnotherModal({
    id: MODALS.ADVANCED_ANALYTICS_CONFIRM_MODAL,
    props: { onDelete, descriptionText, actionText, actionButtonClass },
    className: 'advanced-analytics-confirm-modal',
    closable: false,
  });
}

export const openAdvancedAnalyticsSaveACopyModal = (
  initialData: CreateEditProfileDetailsFormProps,
  existingProfile: FilterProfileDetail,
  onSubmit: (data: CreateEditProfileDetailsFormProps) => void
) =>
  openAnotherModal({
    id: MODALS.ADVANCED_ANALYTICS_SAVE_A_COPY_MODAL,
    props: { initialData, onSubmit, existingProfile },
    className: 'advanced-analytics-save-a-copy-modal',
  });

export function openAddRemoveUserModal(
  orgId: Uuid,
  groupId: Uuid,
  selectedUserIds: Uuid[]
) {
  return openModal({
    title: i18next.t<string>('Add/remove users'),
    id: MODALS.ADD_REMOVE_USER_MODAL,
    props: { orgId, groupId, selectedUserIds },
  });
}

export function openAddProfileRatersModal(
  currentIds: List<string>,
  maxNumUsers: number | undefined,
  updateUsers: (selectedUsers: List<string>) => void,
  setProfileCreatorEmail: (email: string) => void,
  organisationId?: Uuid
) {
  const title = i18next.t<string>('Profile raters');
  return openModal({
    id: MODALS.ADD_PROFILE_RATERS_MODAL,
    title: title,
    props: {
      currentIds,
      maxNumUsers,
      updateUsers,
      setProfileCreatorEmail,
      organisationId,
    },
  });
}
